const kor = {
  jiantizhongwen: '한국인', // 简体中文 (注意：韩文没有直接对应“简体中文”的词汇，这里用拼音表示)  
  xianlu: '라인', // 线路  
  dianwei: '현재위치', // 当前点位  
  wodezichan: '내 자산', // 我的资产  
  touzikuaixun: '투자빠른소식', // 投资快讯  
  jiaoyiyonghu: '거래사용자', // 交易用户  
  jiaoyipinglei: '거래품목', // 交易品类  
  danlunhuoli: '한바퀴 이익', // 单轮获利  
  jiaoyiriqi: '거래일', // 交易日期  
  dengluhouchakan: '로그인 후 확인', // 登陆后查看  
  shouye: '홈페이지', // 首页  
  jiaoyidating: '거래홀', // 交易大厅  
  yucun: '예치', // 预存  
  zoushi: '트랜드', // 走势 (注意：韩文“트랜드”更偏向于英文“trend”的翻译，但在此上下文中可理解为走势)  
  wode: '내', // 我的  
  kefu: '고객서비스', // 客服  
  zuiditouzi: '최저투자액', // 最低投资  
  zuidijinru: '최저입문', // 最低进入 (注意：这个翻译可能需要根据具体上下文调整)  
  jichulicai: '기초재무관리', // 基础理财  
  zishenlicai: '자신재무관리', // 资深理财 (注意：这里用“자신”来代表资深，但可能需要根据具体语境调整)  
  dashilicai: '대사재무관리', // 大师理财 (注意：“대사”在韩文中通常指大师，但可能需要根据具体语境调整)  
  zhuanjialicai: '전가재무관리',
  基础理财: '기본 재무관리',
  资深理财: '고위 재무 관리',
  大师理财: '마스터 재무 관리',
  专家理财: '전문적인 재무관리',
  huiyuanquanyi: '회원의 권리',
  zuoriyingli: '어제 수익',
  zongzichan: '위안바오',
  chucunjin: '보증금',
  jinriyingli: '오늘의 수익',
  zhuanchu: '현금 인출',
  cunru: '충전하다',
  zhanghuxinxni: '계정 정보',
  lishizhangdan: '거래 내역',
  zijinminxi: '기금 세부정보',
  renwuzhongxin: '선교 센터',
  xiaoxigonggao: '뉴스 발표',
  tuijianfenxiang: '공유 추천',
  huodongzhongxin: '활동 센터',
  jibenziliao: '기본정보',
  dengluzhanghao: '로그인 계정',
  nicheng: '닉네임',
  shezhi: '설정',
  genggaixianlu: '경로 변경',
  guanyuwomen: '회사 소개',
  xitongshezhi: '시스템 설정',
  yuyanshezhi: '언어 설정',
  tuichudenglu: '로그아웃',
  bangdingyinhangka: '은행 카드 바인딩',
  bangdingxuniqianbao: '가상 지갑 바인딩',
  anquanma: '보안 코드',
  xiugailenglumima: '로그인 비밀번호 변경',
  yinhangkaguanli: '은행 카드',
  tianjiayinhangka: '은행 카드 추가',
  xuniqianbaoguanli: 'USDT',
  tianjiaxuniqianbao: '가상 지갑 추가',
  chiakren: '카드홀더',
  yinhangkakahao: '은행 카드 번호',
  yinhangmingcheng: '은행명',
  yhkTps: '카드 소지자의 은행카드를 묶어주세요.',
  nixiqianbaodizhi: '가상 지갑 주소',
  xuniqianbaoleixing: '가상 지갑 유형',
  xnqbTps: ' 알림: trc20, erc20, omni와 같은 USDT 주소 유형을 입력하세요. ',
  plchikaren: '카드 소지자를 입력하세요.',
  plkahao: '카드 소지자의 카드번호를 입력해주세요.',
  plyinhangmingcheng: '은행명을 입력해주세요',
  plqbdz: '가상 지갑 주소를 입력해주세요',
  plqblx: '가상 지갑 유형을 입력하세요.',
  shezhianquanma: '보안 코드 설정',
  yuananquanma: '원래 보안 코드',
  xinanquanma: '새로운 보안 코드',
  planquanma: '원래 보안 코드를 입력하세요',
  plxinanquanma: '새로운 보안 코드를 입력해주세요',
  queren: '확인하다',
  quxiao: '취소',
  anquanmaTps: ' 보안코드를 설정하세요. 은행카드 비밀번호와 동일하면 안 됩니다.',
  anquanmaTpsTow: ' 고객님, 이 보안코드는 외부로 이체하실 때 사용되는 비밀번호입니다. 보안상의 이유로 로그인 비밀번호와 동일하게 사용하지 마세요.',
  xiugaidenglu: '비밀번호 변경',
  jiumima: '이전 비밀번호',
  xinmima: '새 비밀번호',
  querenmima: '비밀번호 확인',
  pljiumima: '이전 비밀번호를 입력해주세요',
  plxinmima: '새로운 비밀번호를 입력해주세요',
  plquerenmima: '비밀번호를 다시 입력해주세요',
  wancheng: '마치다',
  kaishitouzi: '투자 시작',
  kaiTps: '현재 회선을 사용할 수 없으면 다른 회선으로 전환해 보세요.',
  zuiyou: '최적의',
  dangaqianxianlu: '현재 라인',
  dangqianbanben: '현재 버전',
  banquansuoyou: '모든 권리 보유',
  yinglizhendong: '어닝쇼크',
  gensuixitong: '시스템을 따르세요',
  zhuanchujine: '이체금액',
  plzhuanchu: '이체금액을 입력해주세요',
  zhuanchuTps: '알림: 질문이 있는 경우 플랫폼 고객 서비스에 문의하세요.',
  xuanzeyh: '출금 은행 카드를 선택하세요.',
  xuanzehb: '출금할 가상 지갑을 선택해주세요',
  tijiaoshenqing: '신청서 제출',
  shuruanquanma: '보안코드를 입력해주세요',
  xianshangcunru: '온라인 입금',
  saomacunru: 'USDT',
  wangyincunru: "은행 예금",
  shijian: '시간',
  upshijian: '시작 시간',
  downshijian: '종료 시간',
  wuTps: '아직 관련 데이터가 없습니다.~',
  jiazaicx: '다시 장전하다',
  di: '아니요.',
  lun: '바퀴',
  weiyingli: '수익성이 없음',
  yishouli: '수락됨',
  zhuangtai: '상태',
  chongzhi: '다시 놓기',
  riqi: '날짜',
  CapActive: {
    chongzhi: '충전하다',
    tixian: '현금 인출',
    goumai: '구입하다',
    yingli: '이익',
    zengsong: '포기하다',
    weitongguo: '실패한',
    yitongguo: '합격',
    yijujue: '거부됨',
  },
  zhanneixiaoxi: "사이트 뉴스",
  pingtaigonggao: '플랫폼 발표',
  fuzhilanjie: "링크 복사",
  denglu: '로그인',
  zhuce: '등록하다',
  jizhumima: '비밀번호 기억하기',
  wangjimima: '비밀번호를 잊어버려',
  youkedenglu: '방문자 로그인',
  zhucezhanghao: "계정 등록",
  plusername: '사용자 이름을 입력하세요',
  plpassword: '비밀번호를 입력해주세요',
  wanchengzhuce: '등록 완료',
  yiyouzhanghao: '이미 계정이 있습니다',
  qingdenglu: '로그인해주세요',
  remenhuati: '뜨거운 주제',
  news1: '원래의',
  news2: '추천하다',
  news3: '재산',
  renliulan: '사람들이 찾아보기',
  hezhi: '합계 값',
  lunshu: '라운드 수',

  大: '큰',
  小: '작은',
  大单: '큰 주문',
  大双: '더블',
  小单: '소액 주문',
  小双: '샤오솽',
  单: '하나',
  双: '쌍',
  极小: '극히 작은',
  极大: '엄청난',
  qi: '예상하다',
  juli: '거리',
  lunjiezhi: '라운드 종료',
  yifengpan: '닫은',
  yiguanbi: '닫은',
  yonghu: '사용자',
  danlunjiaoyi: '단일 라운드 거래',
  caozuo: '작동하다',
  pljoin: '채팅방에 성공적으로 참여했습니다',
  pltingshou: '제품이 단종되었습니다',
  gengou: "후속 구매",
  quedinggengou: "구매를 따르시겠습니까?",
  wanjia: "플레이어",
  leibie: "범주",
  jine: "양",
  gouru: '내기',
  zanweigouru: '아직 구매하지 않음',
  qigou: '최소 구매',
  gourushuliang: '구매 수량',
  changgui: '전통적인',
  shuzi: '숫자',
  yinglihezhi: '이익과 가치',
  shouyijieshao: '소득 소개',
  lijicunru: '지금 입금하세요',
  kaihaojieguo: '번호 공개 결과',
  kaihaoqushi: '오프닝 트렌드',
  chanpingshuoming: '제품 설명',
  qingchuhuancun: '캐시 지우기',
  youke: '관광객',
  gongxi: '축하해요 ',
  zai: ' 존재하다 ',
  yingli: ' 이익 ',
  xiadanchenggong: '주문이 성공적으로 완료되었습니다',
  jiazaizhong: '로드 중...',
  guanbitg: '휴무, 구매중단',
  xiangoudl: '현재 구매 라운드만 팔로우할 수 있습니다.',
  liaotiantip: '시스템에서 귀하를 차단했습니다. 구매를 선택하세요.',
  tishi: '힌트',
  zhidao: '알고 있었다',
  zhuanchusb: '전송 실패',
  zhuanchusbs: '잔액 부족, 이체 실패',
  buyxq: '구매내역',
  orderNo: '청구서 번호',
  plan_name: '객실정보',
  buyxx: '구매정보',
  haoxx: '번호 선택 세부정보',
  buysj: '구매시간',
  yilou: '생략',
  fzjqb: '임시보드에 복사됨',
  chanpin1: '판매개시시간 : 3.5분 간격으로 1회전, 당일 오전 10시에 개장하고 다음날 오전 2시에 마감합니다.',
  chanpin2: '각 라운드에는 0부터 9까지 세 개의 숫자가 최종 결과에 추가됩니다. 투자 결과는 롱, 숏, 플랫, 플랫, 양극, 양극, 음극 및 숫자일 수 있습니다.',
  Tips: {
    wjmm: '비밀번호를 잊어버린 경우 고객센터로 연락하여 비밀번호를 변경해주세요.!',
    dlcg: '로그인 성공',
    tjcg: '제출 성공',
    zanwu: '아직 데이터가 없습니다',
    buyizhi: '두 개의 비밀번호가 일치하지 않습니다',
    zcbz: '자산이 충분하지 않습니다.',
    pltxje: '출금금액을 입력해주세요',
    zdtx: '최소 출금 100',
    youke: '방문자 여러분, 진행하기 전에 정식 회원으로 등록하시기 바랍니다.',
    szcg: '설정 성공',
  },
  共1注: '총 1개의 메모',
  wallet: '지갑',
  guanfangtuijian: '공식 추천',
  yonghudongtai: '사용자 뉴스',
  lijijinru: '지금 입장하세요',
  lijizhuli: '지금 도와주세요',
  zztzz: '베팅 진행 중',
  "立即登录": "지금 로그인하세요",
  "混合": "혼합",
  "特码": "특수코드",
  "玩法": "플레이 방법",
  "游戏规则": "게임 규칙",
  "公告": ' 친애하는 고객 여러분, 매일 밤 12시부터 23시 30분까지 고객 서비스에 문의해 주세요. 귀하의 지원에 감사드립니다. 행복한 삶을 기원합니다! ',
  "短期理财": "단기 재무 관리",
  "长期理财": '장기 재무 관리',
  "初级VIP": "주니어 VIP",
  "中级VIP": "중급 VIP",
  "高级VIP": "프리미엄 VIP",
  "至尊VIP": "최고 VIP",
  "大": "큰",
  "小": "작은",
  "单": "하나",
  "双": "쌍",
  "大单": "큰 주문",
  "大双": "더블",
  "小单": "소액 주문",
  "小双": "샤오솽",
  "极小": "극히 작은",
  "极大": "엄청난",
  "已选择": "선택된",
  "注": "메모",
  "账户充值": "계정 재충전",
  "充值金额": "충전금액",
  "最低充值金额": "최소충전금액",
  "到帐账号": "입금계좌번호",
  "支付方式": "결제수단",
  "充值汇率": "충전 환율",
  "请确认您的账号。最低充值金额为": "계정을 확인해 주세요. 최소 충전금액은",
  "请按照上面提供的支付方式选择进行支付": "위에 제시된 결제 방법에 따라 결제해 주시기 바랍니다.",
  "支付时，请备注您支付的订单号提交审核。提交成功后，请等待到帐确认": "결제 시, 결제하신 주문번호를 메모해 주시고, 검토를 위해 제출해 주시기 바랍니다. 성공적으로 제출한 후 결제 확인을 기다려 주세요.",
  "资金变动": "기금 변경",
  "审核中": "검토중",
  "已通过": "합격",
  "已拒绝": "거부됨",
  "客服中心": "고객 서비스 센터",
  "在线客服": "온라인 고객 서비스",
  "如何充值？": "충전하는 방법?",
  "联系客服，银行卡转账": "고객 서비스에 문의, 은행 카드 송금",
  "忘记了密码怎么办？": "비밀번호를 잊어버린 경우 어떻게 해야 하나요?",
  "请及时提供相应信息给客服，系统将重置一个新密码，为保障账户安全，建议用户登录后立即修改密码。": "적시에 해당 정보를 고객 서비스에 제공하시면 시스템에서 새 비밀번호를 재설정합니다. 계정 보안을 위해 사용자는 로그인 후 즉시 비밀번호를 변경하는 것이 좋습니다. ",
  "如何开通网上支付？": "온라인 결제를 활성화하는 방법은 무엇입니까?",
  "开通银行卡网上支付功能，包括网上开通和现场开通两种方式：": "은행 카드의 온라인 결제 기능 활성화에는 온라인 활성화와 현장 활성화의 두 가지 방법이 있습니다.",
  "（1）网上开通：登录各大银行的官方网站在线开通。": "（1）网上开通：登录各大银行的官方网站在线开通。",
  "（2）现场开通：前往各大银行的营业网点现场开通。": "（2）现场开通：前往各大银行的营业网点现场开通。",
  "提款次数有没有限制？": "출금 횟수에 제한이 있나요?",
  "本公司不限制提款次数。": "회사는 출금횟수에 제한을 두지 않습니다.",
  "提款需要手续费吗？": "인출 시 수수료가 있나요?",
  "达到提款条件才能提款，提款不限次数，手续费根据本站设定。提款时产生的银行手续费将全部由本站为您承担。": "출금은 출금 조건을 충족하는 경우에만 가능합니다. 출금 횟수에는 제한이 없습니다. 본 사이트에 따라 처리 수수료가 설정됩니다. 자금 인출 시 발생하는 모든 은행 수수료는 본 사이트에서 귀하를 대신하여 부담합니다.",
  "邀请码": "초대코드",
  "欢迎光临": "환영",
  "马上注册": "지금 등록하세요",
  "请输入邀请码": "초대코드를 입력해주세요",
  "请输入验证码": "인증번호를 입력해주세요",
  "我已年满18周岁且同意接受": "나는 18세 이상이며 이에 동의합니다.",
  "立即登陆": "지금 로그인하세요",
  "恭喜中奖了": "수상을 축하드립니다",
  "太棒了！恭喜获得！": "기이! 축하해요!",
  "和值竞猜游戏，依照福利彩兼发行中心统一发行的竞猜游戏 彩票开奖为依据所规划的线上彩票游戏，完全公开、公平、公正、开奖透明，无法作弊": " 합산가치추측게임은 복지복권배급센터에서 발행한 추첨결과를 바탕으로 기획된 온라인 복권게임으로, 완전 공개적이고 공정하며 공정하고 투명하며 부정행위를 할 수 없습니다.",
  "竞猜游戏实行自愿购买，凡购买者均被视为同意并遵守本规则": "추측 게임은 자발적인 구매이며, 모든 구매자는 이러한 규칙에 동의하고 이를 준수하는 것으로 간주됩니다.",
  "游戏介绍": "게임 소개",
  "游戏玩法": "게임 플레이",
  "将叁个数值进行相加后所得结果，即为和值": "3개의 값을 더한 결과가 합이 됩니다.",
  "温馨提示": "친절한 팁",
  "平台禁止在同一期反向购买两注相同金额（即大、小或者单、双），此操作属于违法行为，一经发现，将冻结其账号": " 플랫폼은 같은 기간에 동일한 금액(예: 대, 소, 홀수 또는 짝수)의 두 배팅을 역구매하는 것을 금지합니다. 이 작업은 불법이며 적발되면 계정이 동결됩니다. ",
  "平台每日提现需完成账户余额一倍打码量，没有完成当日打码量的，次日零点清零重新计算": " 플랫폼의 일일 현금 출금은 계좌 잔고의 코딩 금액의 두 배를 완료해야 합니다. 당일 코딩 금액이 완료되지 않으면 다음날 0시에 재설정되어 다시 계산됩니다. ",
  "保存": "구하다",
  "更改头像": "아바타 변경",
  "身份证号码": "ID 번호",
  "请输入身份证号码": "귀하의 ID 번호를 입력해주세요",
  "更改头像": "아바타 변경",
  "身份证号码": "ID 번호",
  "请输入身份证号码": "귀하의 ID 번호를 입력해주세요",
  "从相册选择": "앨범에서 선택",
  "拍照": "사진",
  "系统默认图片": "시스템 기본 그림",
  "冻结金额": "동결 금액",
  "提现资金不足": "출금 자금이 부족합니다",
  "您的资产不足请存入": "자산이 부족하니 입금해 주세요.",
  "我的团队": "나의 팀",
  "用户名": "사용자 이름",
  "余额": "잔액",
  "类型": "유형",
  "做多": "매수",
  "做空": "매도",
  "平单": "청산",
  "平双": "청산 쌍",
  "姓名": "이름",
  "手机号": "전화번호",
  "流水必須達到": "거래내역은",
}
export default kor;