import {
    setToken, clearToken, setsessionStorage, delsessionStorage, setCurrentUserId, clearCurrentUserId, setCurrentUser, setCurrentUserMoney, setCurrentUseractiveList,
    setCurrentUserGroup_id, setCurrentUserusercrud, setCurrentUserMobile
} from "./token";
import { loginDone } from "./user";
export default {
    loginDone,
    setToken,
    clearToken,
    setsessionStorage,
    delsessionStorage,
    setCurrentUserId, clearCurrentUserId, setCurrentUser, setCurrentUserMoney, setCurrentUseractiveList, setCurrentUserGroup_id,
    setCurrentUserusercrud,
    setCurrentUserMobile
}