<template>
  <div class="lottery">
    <header>
      <div class="g-header" style="background: rgb(14, 21, 48)">
        <div class="left" @click="changego">
          <van-icon name="arrow-left" color="#fff" size="20px" />
        </div>
        <div class="middle fs-18">{{ $t(name) }}</div>
        <div class="right">
          <div class="bg-icon bg-icon-setting"></div>
        </div>
      </div>
      <div class="blockHeight"></div>
    </header>
    <div class="g-content mescroll">
      <div
        class="list-item"
        v-for="item in gameList"
        :key="item.id"
        @click="
          changeroom(item.id, item.game_id, name, item.title, item.join_limit)
        "
      >
        <div class="item-title">{{ $t(item.title) }}</div>
        <div class="item-text">{{ $t("lijizhuli") }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import TabBar from "@/components/tabbar/TabBar.vue";
import Swiper from "swiper";
import "swiper/dist/css/swiper.css";
import homeApi from "@/api/home";
export default {
  name: "Lottery",
  components: {
    TabBar,
  },
  data() {
    return {
      curr: 1,
      activeLines: "left: 0%; width: 25%",
      SweipeIndex: 0,
      gameList: "",
      id: "",
      name: "",
    };
  },
  computed: {
    money() {
      console.log(this.$store.state.money);
      return this.$store.state.money;
    },
  },
  methods: {
    changeroom(id, game_id, name, title, joinmoeny) {
      // 判断进入金额和自己的金额
      console.log(this.money * 1, joinmoeny * 1);
      if (this.money * 1 < joinmoeny * 1) {
        // alert(1)
        this.$toast(this.$t("Tips.zcbz") + joinmoeny);
        return;
      }
      this.$router.push({
        path: "/betRoom",
        query: {
          id: id,
          game_id: game_id,
          name: name,
          title: title,
        },
      });
    },
    changego() {
      this.$router.go(-1);
    },
  },
  mounted() {
    this.$toast.loading({
      message: this.$t("jiazaizhong"),
      forbidClick: true,
      duration: 0,
    });
    homeApi
      .Lotteryinfo("", "")
      .then((data) => {
        if (data.data.gameList.length > 0) {
          data.data.gameList.map((item) => {
            if (item.id == this.id) {
              this.gameList = item.planList;
            }
          });
        }
        this.$toast.clear();
      })
      .catch((err) => {
        this.$toast.clear();
        this.$toast.fail(err.msg);
      });
  },
  created() {
    this.id = this.$route.query.id || "";
    this.name = this.$route.query.name || "";
  },
};
</script>
<style lang="less" scoped>
.lottery {
  height: 100%;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: #181a29;
}

.mescroll {
  padding: 0 2.5% 0.5rem;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  overflow-y: auto;
}

.tab-box-wrap {
  padding: 0 0.24rem;
}

.tab-box {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0.36rem 0 0.54rem;
  border: 1px solid #4bb3e8;
  border-radius: 4px;
  position: relative;
}

.tab {
  flex: 1;
  padding: 0.4em 0;
  font-size: 0.35rem;
  font-weight: 700;
  text-align: center;
}

.active {
}

.tab_lines {
  position: absolute;
  height: 100%;
  background: #4bb3e8;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  z-index: -1;
}

.swiper-container {
  // height: 300px;
  width: 100%;

  .swiper-wrapper {
    .swiper-slide {
    }
  }
}

.level-box {
}

.level {
  height: 3rem;
  margin-bottom: 0.2rem;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url(../assets/image/bg.png);
  text-align: left;
}

.level1 {
  height: 3rem;
  margin-bottom: 0.2rem;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url(../assets/image/bg2.png);
  text-align: left;
}

.level2 {
  height: 3rem;
  margin-bottom: 0.2rem;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url(../assets/image/bg3.png);
  text-align: left;
}

.level3 {
  height: 3rem;
  margin-bottom: 0.2rem;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url(../assets/image/bg4.png);
  text-align: left;
}

.title {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.5rem 0.56rem 0;
  font-size: 0.45rem;
}

.level-name {
  width: 2.5rem;
}

.people-box {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;

  margin-left: 0.2rem;
  font-size: 0.45rem;

  .icon {
    margin-right: 0.06rem;
    width: 0.5rem;
    height: 0.5rem;
  }
}

.text-box {
  padding: 0.3rem 0.56rem 0;
  font-size: 0.3rem;
  text-align: left;
}

.text {
  min-width: 2.1rem;
}

.price {
  padding-left: 0.1rem;
}

.text:last-child {
  margin-top: 0.2rem;
}

.list-item {
  position: relative;
  margin-top: 0.3rem;
  height: 4rem;
  background: url("../assets/image/a3.png") 100% 100% no-repeat;
  background-size: contain;

  .item-title {
    position: absolute;
    top: 1rem;
    left: 0.5rem;
    font-size: 0.55rem;
  }

  .item-text {
    position: absolute;
    bottom: 0.5rem;
    left: 0.5rem;
    font-size: 0.4rem;
  }
}
</style>
