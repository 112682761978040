<template>
  <van-swipe :autoplay="5000" class="swipe" :show-indicators="false">
    <van-swipe-item v-for="(image, index) in images" :key="index">
      <img :src="$imageUrl + image.image" />
      <img :src="image.image" />
    </van-swipe-item>
  </van-swipe>

  <!-- <van-swipe :autoplay="5000" class="swipe" :show-indicators="true">
    <van-swipe-item v-for="(image, index) in images" :key="index">
      <img :src="image.image" />
    </van-swipe-item>
  </van-swipe> -->
</template>

<script>
import homeApi from "@/api/home";
export default {
  data() {
    return {
      images: [],
    };
  },
  computed: {},
  methods: {
    info() {
      homeApi
        .homeindex()
        .then((data) => {
          console.log(data.data);
          this.images = data.data.banner;

          this.$toast.clear();
        })
        .catch((err) => {
          this.$toast.clear();
          this.$toast.fail(err.msg);
        });
    },
  },
  mounted() {},
  created() {
    this.info();
  },
};
</script>
<style lang="less" scoped>
.swipe {
  margin: 0.4rem;
  height: 4.1rem;
  border-radius: 10px;

  .van-swipe-item {
    border-radius: 10px;
  }

  img {
    height: 100%;
    width: 100%;
    border-radius: 10px;
  }
}
</style>
