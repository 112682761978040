const tw = {
    jiantizhongwen: '繁體中文',
    xianlu: '線路',
    dianwei: '當前點位',
    wodezichan: '我的資產',
    touzikuaixun: '投資資訊',
    jiaoyiyonghu: '交易用戶',
    jiaoyipinglei: '交易品類',
    danlunhuoli: '單輪獲利',
    jiaoyiriqi: '交易日期',
    dengluhouchakan: '登陸後查看',
    shouye: '首頁',
    jiaoyidating: '交易大廳',
    yucun: '預存',
    zoushi: '走勢',
    kefu: '客服',
    wode: '我的',
    zuiditouzi: '最低投資',
    zuidijinru: '最低進入',
    jichulicai: '基礎理財',
    zishenlicai: '資深理財',
    dashilicai: '大師理財',
    zhuanjialicai: '專家理財',
    基础理财: '基礎理財',
    资深理财: '資深理財',
    大师理财: '大師理財',
    专家理财: '專家理財',
    huiyuanquanyi: '會員權益',
    zuoriyingli: '昨日盈利',
    zongzichan: '元寶',
    chucunjin: '儲存金',
    jinriyingli: '今日盈利',
    zhuanchu: '提現',
    cunru: '充值',
    zhanghuxinxni: '賬戶信息',
    lishizhangdan: '交易記錄',
    zijinminxi: '資金明細',
    renwuzhongxin: '任務中心',
    xiaoxigonggao: '消息公告',
    tuijianfenxiang: '推薦分享',
    huodongzhongxin: '活動中心',
    jibenziliao: '基本資料',
    dengluzhanghao: '登錄賬號',
    nicheng: '昵称',
    shezhi: '設置',
    genggaixianlu: '更改線路',
    guanyuwomen: '關於我們',
    xitongshezhi: '系統設置',
    yuyanshezhi: '語言設置',
    tuichudenglu: '退出登錄',
    bangdingyinhangka: '綁定銀行卡',
    bangdingxuniqianbao: '綁定虛擬錢包',
    anquanma: '安全碼',
    xiugailenglumima: '修改登錄密碼',
    yinhangkaguanli: '銀行卡管理',
    tianjiayinhangka: '添加銀行卡',
    xuniqianbaoguanli: '虛擬錢包管理',
    tianjiaxuniqianbao: ' 添加虛擬錢包',
    chiakren: '持卡人',
    yinhangkakahao: '銀行戶口號',
    yinhangmingcheng: '銀行名稱',
    yhkTps: '請綁定持卡人本人的銀行卡',
    nixiqianbaodizhi: '虛擬錢包地址',
    xuniqianbaoleixing: '虛擬錢包類型',
    xnqbTps: ' 溫馨提示：請填寫USDT地址類型，如：trc20、erc20、omni。',
    plchikaren: '請輸入持卡人姓名',
    plkahao: '請輸入正確的銀行戶口號',
    plyinhangmingcheng: '請輸入銀行名稱',
    plqbdz: '請輸入虛擬錢包地址',
    plqblx: '請輸入虛擬錢包類型',
    shezhianquanma: '重置安全碼',
    yuananquanma: '原安全碼',
    xinanquanma: '新安全碼',
    planquanma: '請輸入原安全碼',
    plxinanquanma: '請輸入新安全碼',
    queren: '確認',
    quxiao: '取消',
    anquanmaTps: '請設置安全碼，請勿與銀行卡密碼相同',
    anquanmaTpsTow: '尊敬的客戶，此安全碼是您轉出時的密碼，為了安全起見，請盡量不要與登錄密碼相同',
    xiugaidenglu: '修改登錄密碼',
    jiumima: '舊密碼',
    xinmima: '新密碼',
    querenmima: '確認密碼',
    pljiumima: '請輸入舊密碼',
    plxinmima: '請輸入新密碼',
    plquerenmima: '請再次輸入密碼',
    wancheng: '完成',
    kaishitouzi: '開始投資',
    kaiTps: '當前線路無法使用時，可嘗試切換其他線路',
    zuiyou: '最優',
    dangaqianxianlu: '當前線路',
    dangqianbanben: '當前版本',
    banquansuoyou: '版權所有',
    yinglizhendong: '盈利震動',
    gensuixitong: '跟隨系統',
    zhuanchujine: '轉出金額',
    plzhuanchu: '請輸入轉出金額',
    zhuanchuTps: '如有疑問請聯系客服',
    xuanzeyh: '請選擇提款銀行卡',
    xuanzehb: '請選擇提款虛擬錢包',
    tijiaoshenqing: '提交申請',
    shuruanquanma: '請輸入安全碼',
    xianshangcunru: '線上存入',
    saomacunru: 'USDT',
    wangyincunru: "網銀存入",
    shijian: '時間',
    upshijian: '開始時間',
    downshijian: '結束時間',
    wuTps: '暫無相關數據~',
    jiazaicx: '重新加載',
    di: '第',
    lun: '輪',
    weiyingli: '未盈利',
    yishouli: '已受理',
    zhuangtai: '狀態',
    chongzhi: '重置',
    riqi: '日期',
    CapActive: {
        chongzhi: '充值',
        tixian: '提現',
        goumai: '购买',
        yingli: '購買',
        zengsong: '贈送',
        weitongguo: '未通過',
        yitongguo: '已通過',
        yijujue: '已拒絕',
    },
    zhanneixiaoxi: "站内消息",
    pingtaigonggao: '平臺公告',
    fuzhilanjie: "復制鏈接",
    denglu: '登錄',
    jizhumima: '記住密碼',
    wangjimima: '忘記密碼',
    youkedenglu: '遊客登錄',
    zhucezhanghao: "立即註冊",
    plusername: '請輸入用戶名',
    plpassword: '請輸入密碼',
    wanchengzhuce: '完成註冊',
    yiyouzhanghao: '已有賬戶',
    qingdenglu: '請登錄',
    zhuce: '注册',
    remenhuati: '熱門話題',
    news1: '原創',
    news2: '推薦',
    news3: '財富',
    renliulan: '人瀏覽',
    hezhi: '和值',
    lunshu: '輪數',

    做多: '大',
    做空: '小',
    多单: '大單',
    多双: '大雙',
    空单: '小單',
    空双: '小雙',
    平单: '單',
    平双: '雙',
    极小: '極小',
    极大: '極大',
    qi: '期',
    juli: '距離',
    lunjiezhi: '輪截至',
    yifengpan: '已封盤',
    yiguanbi: '已關閉',
    yonghu: '用戶',
    danlunjiaoyi: '單輪交易',
    caozuo: '操作',
    pljoin: '加入聊天室成功',
    pltingshou: '產品已停售',
    gengou: "跟購",
    quedinggengou: "確定跟購嗎",
    wanjia: "玩家",
    leibie: "類別",
    jine: "金額",
    gouru: '投注',
    zanweigouru: '暫未購入',
    qigou: '起購',
    gourushuliang: '購入數量',
    changgui: '常規',
    shuzi: '數位',
    yinglihezhi: '盈利和值',
    shouyijieshao: '收益介紹',
    lijicunru: '立即存入',
    kaihaojieguo: '開號結果',
    kaihaoqushi: '開號趨勢',
    chanpingshuoming: '產品說明',
    qingchuhuancun: '清除緩存',
    youke: '游客',
    gongxi: '恭喜 ',
    zai: ' 在 ',
    yingli: ' 盈利 ',
    xiadanchenggong: '下單成功',
    jiazaizhong: '加載中...',
    guanbitg: '已關閉，停止購入',
    xiangoudl: '只能跟購當前輪',
    liaotiantip: '系統已禁言，請選擇購入',
    tishi: '提示',
    zhidao: '知道了',
    zhuanchusb: '轉出失敗',
    zhuanchusbs: '餘額不足，轉出失敗',
    buyxq: '購入詳情',
    orderNo: '賬單編號',
    plan_name: '廳房信息',
    buyxx: '購入信息',
    haoxx: '選號詳情',
    buysj: '購入時間',
    yilou: '遺漏',
    fzjqb: '已復製到粘貼板',
    chanpin1: '開售時間：每3.5分鐘為一輪，當日10:00AM開盤，次日02:00AM停盤。 ',
    chanpin2: '每輪有3個0-9的數字，3個數字相加為最終結果，可以投資結果做多做空平單平雙極陽極陰以及數字。 ',
    Tips: {
        wjmm: '忘記密碼請聯繫客服修改!',
        dlcg: '登錄成功',
        tjcg: '提交成功',
        zanwu: '暫無數據',
        buyizhi: '兩次密碼不一致',
        zcbz: '您的資產不足',
        pltxje: '請輸入提現金額',
        zdtx: '最低提現100',
        youke: '尊敬的遊客您好：請您註冊正式會員後操作。 ',
        szcg: '設置成功',
    },
    共1注: '共1注',
    wallet: '錢包',
    guanfangtuijian: '官方推薦',
    yonghudongtai: '用戶動態',
    lijijinru: '立即進入',
    lijizhuli: '立即助力',
    zztzz: '正在投注中',
    "立即登錄": "立即登錄",
    "混合": "混合",
    "特码": "特碼",
    "玩法": "玩法",
    "游戏规则": "遊戲規則",
    "公告": '尊敬的客戶您好，平台夜間停止辦理業務，請於每日12:00---23:30聯繫客服辦理業務，感謝您對的支持，祝您生活愉快！ ',
    "短期理财": '短期理財',
    "长期理财": '長期理財',
    "初级VIP": "初級VIP",
    "中级VIP": "中級VIP",
    "高级VIP": "高級VIP",
    "至尊VIP": "至尊VIP",
    "大": "大",
    "小": "小",
    "单": "單",
    "双": "雙",
    "大单": "大單",
    "大双": "大雙",
    "小单": "小單",
    "小双": "小雙",
    "极小": "極小",
    "极大": "極大",
    "已选择": "已選擇",
    "注": "注",
    "账户充值": "賬戶充值",
    "充值金额": "充值金額",
    "最低充值金额": "最低充值金額",
    "到帐账号": "到賬賬號",
    "支付方式": "支付方式",
    "充值汇率": "充值匯率",
    "请确认您的账号。最低充值金额为": "請確認您的賬號。最低充值金額為",
    "请按照上面提供的支付方式选择进行支付": "請按照上面提供的支付方式選擇進行支付",
    "支付时，请备注您支付的订单号提交审核。提交成功后，请等待到帐确认": "支付時，請備註您支付的訂單號提交審核。提交成功後，請等待到賬確認",
    "资金变动": "資金變動",
    "审核中": "審核中",
    "已通过": "已通過",
    "已拒绝": "已拒絕",
    "客服中心": "客服中心",
    "在线客服": "在線客服",
    "如何充值？": "如何充值？",
    "联系客服，银行卡转账": "聯系客服，銀行卡轉賬",
    "忘记了密码怎么办？": "忘記了密碼怎麼辦？",
    "请及时提供相应信息给客服，系统将重置一个新密码，为保障账户安全，建议用户登录后立即修改密码。": "請及時提供相應信息給客服，系統將重置一個新密碼，為保障賬戶安全，建議用戶登錄後立即修改密碼。",
    "如何开通网上支付？": "如何開通網上支付？",
    "开通银行卡网上支付功能，包括网上开通和现场开通两种方式：": "開通銀行卡網上支付功能，包括網上開通和現場開通兩種方式：",
    "（1）网上开通：登录各大银行的官方网站在线开通。": "（1）網上開通：登錄各大銀行的官方網站在線開通。",
    "（2）现场开通：前往各大银行的营业网点现场开通。": "（2）現場開通：前往各大銀行的營業網點現場開通。",
    "提款次数有没有限制？": "提款次數有沒有限制？",
    "本公司不限制提款次数。": "本公司不限制提款次數。",
    "提款需要手续费吗？": "提款需要手續費嗎？",
    "达到提款条件才能提款，提款不限次数，手续费根据本站设定。提款时产生的银行手续费将全部由本站为您承担。": "達到提款條件才能提款，提款不限次數，手續費根據本站設定。提款時產生的銀行手續費將全部由本站為您承擔。",
    "邀请码": "邀請碼",
    "欢迎光临": "歡迎光臨",
    "马上注册": "馬上註冊",
    "请输入邀请码": "請輸入邀請碼",
    "请输入验证码": "請輸入驗證碼",
    "我已年满18周岁且同意接受": "我已年滿18周歲且同意接受",
    "立即登陆": "立即登錄",
    "恭喜中奖了": "恭喜中奖了",
    "太棒了！恭喜获得！": "太棒了！恭喜獲得！",
    "和值竞猜游戏，依照福利彩兼发行中心统一发行的竞猜游戏 彩票开奖为依据所规划的线上彩票游戏，完全公开、公平、公正、开奖透明，无法作弊": "和值竞猜遊戲，依照福利彩兼發行中心統一發行的竞猜游戏 彩票开奖為依據所規劃的線上彩票遊戲，完全公開、公平、公正、開獎透明，無法作弊",
    "竞猜游戏实行自愿购买，凡购买者均被视为同意并遵守本规则": "竞猜遊戲實行自願購買，凡購買者均被視為同意並遵守本規則",
    "游戏介绍": "遊戲介紹",
    "游戏玩法": "遊戲玩法",
    "将叁个数值进行相加后所得结果，即为和值": "將三個數值進行相加後所得結果，即為和值",
    "温馨提示": "溫馨提示",
    "平台禁止在同一期反向购买两注相同金额（即大、小或者单、双），此操作属于违法行为，一经发现，将冻结其账号": "平台禁止在同一期反向購買兩注相同金額（即大、小或者單、雙），此操作屬於違法行為，一經發現，將凍結其賬號",
    "平台每日提现需完成账户余额一倍打码量，没有完成当日打码量的，次日零点清零重新计算": "平台每日提現需完成賬戶餘額一倍打碼量，沒有完成當日打碼量的，次日零點清零重新計算",
    "保存": "保存",
    "更改头像": "更改頭像",
    "身份证号码": "身份證號碼",
    "请输入身份证号码": "請輸入身份證號碼",
    "更改头像": "更改頭像",
    "身份证号码": "身份證號碼",
    "请输入身份证号码": "請輸入身份證號碼",
    "从相册选择": "從相冊選擇",
    "拍照": "拍照",
    "系统默认图片": "系統默認圖片",
    "冻结金额": "凍結金額",
    "提现资金不足": "提現資金不足",
    "您的资产不足请存入": "您的資產不足請存入",
    "我的团队": "我的團隊",
    "用户名": "用户名",
    "余额": "餘額",
    "类型": "類型",
    "做多": "做多",
    "做空": "做空",
    "平单": "平单",
    "平双": "平双",
    "姓名": "姓名",
    "手机号": "手機號碼",
    "流水必須達到": "流水必須達到"
}
export default tw;