<template>
  <div class="rule">
    <header>
      <div class="g-header" style="background: rgb(14, 21, 48)">
        <div class="left" @click="changego">
          <van-icon name="arrow-left" color="#fff" size="20px" />
        </div>
        <div class="middle fs-18">{{ $t("游戏规则") }}</div>
        <div class="right">
          <div class="bg-icon bg-icon-setting"></div>
        </div>
      </div>
      <div class="blockHeight"></div>
    </header>
    <div class="content">
      <div class="title">
        {{ $t("游戏介绍") }}
      </div>
      <p>
        {{
          $t(
            "和值竞猜游戏，依照福利彩兼发行中心统一发行的竞猜游戏 彩票开奖为依据所规划的线上彩票游戏，完全公开、公平、公正、开奖透明，无法作弊"
          )
        }}
      </p>
      <p>{{ $t("竞猜游戏实行自愿购买，凡购买者均被视为同意并遵守本规则") }}</p>
      <div class="title">
        {{ $t("游戏玩法") }}
      </div>
      <p>
        <span>{{ $t("将叁个数值进行相加后所得结果，即为和值") }}</span>
      </p>
      <p>
        <span>{{ $t("大") }}：</span>
        14,15,16,17,18,19,20,21,22,23,24,25,26,27
      </p>
      <p>
        <span>{{ $t("小") }}：</span>
        0,1,2,3,4,5,6,7,8,9,10,11,12,13
      </p>
      <p>
        <span>{{ $t("单") }}：</span>
        1,3,5,7,9,11,13,15,17,19,21,23,25,27
      </p>
      <p>
        <span>{{ $t("双") }}：</span>
        2,4,6,8,10,12,14,16,18,20,22,24,26
      </p>
      <p>
        <span>{{ $t("大单") }}：</span>15,17,19,21,23,25,27
      </p>
      <p>
        <span>{{ $t("小单") }}：</span>1,3,5,7,9,11,13
      </p>
      <p>
        <span>{{ $t("大双") }}：</span>14,16,18,20,22,24,26
      </p>
      <p>
        <span>{{ $t("小双") }}：</span>0,2,4,6,8,10,12
      </p>
      <p>
        <span>{{ $t("极大") }}：</span>22.23,24,25,26,27
      </p>
      <p>
        <span>{{ $t("极小") }}：</span>0,1,2,3,4.5
      </p>
      <div class="title">
        {{ $t("温馨提示") }}
      </div>
      <p>
        {{
          $t(
            "平台禁止在同一期反向购买两注相同金额（即大、小或者单、双），此操作属于违法行为，一经发现，将冻结其账号"
          )
        }}
      </p>
      <p>
        {{
          $t(
            "平台每日提现需完成账户余额一倍打码量，没有完成当日打码量的，次日零点清零重新计算"
          )
        }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Rule",
  data() {
    return {};
  },
  methods: {
    changego() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.rule {
  height: 100vh;
  overflow-y: auto;
  background-color: #efefef;
  text-align: left;
  .content {
    margin: 10px;
    padding: 20px;
    padding-top: 10px;
    background-color: #fff;
    border-radius: 5px;
    color: #666666;
    .title {
      margin: 15px 0;
      position: relative;
      font-size: 15px;
      font-weight: bold;
      &::before {
        position: absolute;
        left: -10px;
        top: 50%;
        transform: translateY(-50%);
        content: "";
        width: 2px;
        height: 80%;
        background-color: #226f83;
      }
    }
    p {
      margin: 5px 0;
      font-size: 15px;
      span {
        color: #cb0816;
      }
    }
  }
}
</style>
