<template>
  <div class="Login" style="height: 100vh; font-size: 50px !important">
    <div class="LoginItem">
      <div class="login_bar">
        <div @click="changeRouteHome" class="back-btn">
          <van-icon name="arrow-left" color="#fff" size="20px" />
        </div>

        <div class="page-title">{{ $t("zhuce") }}</div>

        <div class="lang_cur" @click="toggleLanguage">
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding-right: 8px;
            "
          >
            <!-- <img
              id="vector1"
              src="https://www.hpdrmcsn.cc/statics/images/login/Vector.png?v=3"
              alt="lang-icon"
            /> -->
            <div style="padding: 0 8px; font-size: 12px">{{ currentLang }}</div>

            <!-- <img
              id="vector2"
              src="https://www.hpdrmcsn.cc/statics/images/login/arrow_bottom.png?v=2"
              alt="dropdown-icon"
            /> -->
          </div>
        </div>
      </div>

      <div class="lang_lump" v-show="showlang">
        <div
          class="lang"
          :style="{ color: show === 1 ? '#FFC107' : 'white' }"
          @click="(show = 1), languageSet(1)"
        >
          简
        </div>
        <div
          class="lang"
          :style="{ color: show === 2 ? '#FFC107' : 'white' }"
          @click="(show = 2), languageSet(2)"
        >
          繁
        </div>
        <div
          class="lang"
          :style="{ color: show === 3 ? '#FFC107' : 'white' }"
          @click="(show = 3), languageSet(3)"
        >
          En
        </div>
      </div>

      <header class="login-header">
        <img
          class="login-logo"
          id="login-logo"
          src="../assets/image/login-logo.png"
        />
      </header>

      <content>
        <div class="content-input">
          <section class="lotteryTitle">
            <div style="text-align: left; width: 100%; font-size: 16px">
              {{ $t("欢迎光临") }}
            </div>
          </section>

          <van-cell-group class="login-body van-cell-group">
            <van-field
              :placeholder="$t('plusername')"
              v-model="username"
              clearable
              style="margin-top: 10px; margin-bottom: 15px"
              >>
              <template #left-icon>
                <img
                  style="margin: 8px 5px 0px 0px; width: 22px; height: 22px"
                  src="../assets/image/icon_user.png"
                  class="left-form-icon"
                  alt=""
                />
              </template>
            </van-field>

            <van-field
              clearable
              :placeholder="$t('plpassword')"
              :type="eyeis ? 'text' : 'password'"
              v-model="password"
              style="display: flex; margin-bottom: 15px"
            >
              <!-- 左侧图标 -->
              <template #left-icon>
                <img
                  style="margin: 8px 5px 0px 0px; width: 22px; height: 22px"
                  src="../assets/image/icon_pwd.png"
                  class="left-form-icon"
                  alt=""
                />
              </template>

              <!-- 右侧切换眼睛图标 -->
              <template #right-icon>
                <img
                  v-if="eyeis"
                  src="../assets/image/ico_openeye.png"
                  class="eye-icon"
                  alt=""
                  @click="togglePasswordVisibility"
                />
                <img
                  v-else
                  src="../assets/image/ico_closeeye.png"
                  class="eye-icon"
                  alt=""
                  @click="togglePasswordVisibility"
                />
              </template>
            </van-field>

            <!-- 验证码输入框 -->
            <van-field
              clearable
              :placeholder="$t('请输入邀请码')"
              v-model="inputinvitecode"
              style="margin-bottom: 15px"
            >
              <!-- 左侧图标 -->
              <template #left-icon>
                <img
                  style="margin: 8px 5px 0px 0px; width: 22px; height: 22px"
                  src="../assets/image/icon_validate.png"
                  class="left-form-icon"
                  alt=""
                />
              </template>
            </van-field>

            <!-- 验证码输入框 -->
            <van-field
              clearable
              :placeholder="$t('请输入验证码')"
              v-model="inputCode"
              style="margin-bottom: 10px"
            >
              <!-- 左侧图标 -->
              <template #left-icon>
                <img
                  style="margin: 8px 5px 0px 0px; width: 22px; height: 22px"
                  src="../assets/image/icon_validate.png"
                  class="left-form-icon"
                  alt=""
                />
              </template>

              <!-- 右侧验证码图片 -->
              <template #right-icon>
                <div style="overflow: hidden; height: 100%">
                  <canvas
                    ref="captchaCanvas"
                    @click="generateCaptcha"
                    width="80"
                    height="30"
                  ></canvas>
                </div>
              </template>
            </van-field>

            <van-cell style="padding: 0px">
              <div class="Register-clause">
                <img src="../assets/image/icon_right1.png" />
                <div>{{ $t("我已年满18周岁且同意接受") }}</div>
              </div>

              <div class="input-link">
                <a class="active-opacity" @click="gologin()">{{
                  $t("立即登陆")
                }}</a>
              </div>

              <van-button
                type="primary"
                round
                block
                class="Btnlogin"
                @click="changeHome"
                >{{ $t("zhuce") }}</van-button
              >
            </van-cell>

            <!-- <van-cell style="padding: 10px 0px;">
							<van-button type="primary" round block class="Btnlogin"
								@click="changeHome">{{ $t("denglu") }}</van-button>
						</van-cell> -->
          </van-cell-group>
        </div>
      </content>
    </div>
  </div>
</template>

<script>
import loginApi from "@/api/login";
import storeAction from "@/store/typed-actions";
import AlertPop from "@/components/alert/AlertPop.vue";

export default {
  name: "Login",
  components: {
    AlertPop,
  },

  data() {
    return {
      showlang: false,
      show: 0,
      currentLang: "简",
      eyeis: false,
      checked: true,
      username: "",
      password: "",
      inputCode: "", // 用户输入的验证码
      generatedCode: "", // 随机生成的验证码
      inputinvitecode: "",
      PopAlert: false,
      TipsTxt: this.$t("Tips.wjmm"),
    };
  },

  mounted() {
    // 在组件挂载后，生成验证码
    this.generateCaptcha();
    this.inputinvitecode = this.$route.query.inviter_id || "";
  },

  methods: {
    gologin() {
      this.$router.push({
        path: "/login",
      });
    },

    toggleLanguage() {
      this.showlang = !this.showlang;
    },
    languageSet(type) {
      if (type == 0) {
        localStorage.setItem("locale", "zh");
        localStorage.setItem("localetype", type);
        this.show = 0;
      } else if (type == 1) {
        localStorage.setItem("locale", "zh");
        localStorage.setItem("localetype", type);
        this.show = 1;
      } else if (type == 2) {
        localStorage.setItem("locale", "tw");
        localStorage.setItem("localetype", type);
        this.show = 2;
      } else if (type == 3) {
        localStorage.setItem("locale", "en");
        localStorage.setItem("localetype", type);
        this.show = 3;
      }
      location.reload();
    },
    // 生成验证码
    generateCaptcha() {
      const canvas = this.$refs.captchaCanvas;
      const ctx = canvas.getContext("2d");

      // 清空之前的验证码
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // 生成随机的验证码内容
      const chars = "ABCDEFGHJKLMNPQRSTUVWXYZ23456789";
      let captcha = "";
      for (let i = 0; i < 4; i++) {
        captcha += chars[Math.floor(Math.random() * chars.length)];
      }
      this.generatedCode = captcha;

      // 设置背景颜色和字体样式
      ctx.fillStyle = "#f0f0f0";
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.font = "12px Arial"; // 调整字体大小为 20px
      ctx.fillStyle = "#333";

      // 绘制验证码
      for (let i = 0; i < captcha.length; i++) {
        const x = 10 + i * 20;
        const y = 20 + Math.random() * 5; // 随机高度稍小，控制字符在较小的高度内
        const deg = (Math.random() * 30 * Math.PI) / 180; // 随机旋转角度

        // 保存当前画布的状态
        ctx.save();

        // 旋转字符
        ctx.translate(x, y);
        ctx.rotate(deg);
        ctx.fillText(captcha[i], 0, 0);

        // 恢复画布的状态
        ctx.restore();
      }

      // 添加干扰线和干扰点的代码可以保留不变
    },
    // 随机颜色生成函数
    randomColor() {
      const r = Math.floor(Math.random() * 256);
      const g = Math.floor(Math.random() * 256);
      const b = Math.floor(Math.random() * 256);
      return `rgb(${r},${g},${b})`;
    },
    togglePasswordVisibility() {
      // 切换密码可见性
      this.eyeis = !this.eyeis;
    },
    changeRegister() {
      this.$router.push({
        path: "/register",
      });
    },
    changeHome() {
      let code = "";
      if (this.inputCode) {
        code = this.inputCode.toUpperCase();
      }
      if (!this.username) {
        this.$toast(this.$t("plusername"));
        return;
      } else if (!this.password) {
        this.$toast(this.$t("plpassword"));
        return;
      } else if (!this.inputinvitecode) {
        this.$toast(this.$t("请输入邀请码"));
        return;
      } else if (!this.inputCode) {
        this.$toast(this.$t("請輸入驗證碼"));
        return;
      } else if (code != this.generatedCode) {
        this.$toast(this.$t("請輸入驗證碼"));
        return;
      } else {
        let invitecode = "";
        if (this.inputinvitecode && this.inputinvitecode > 100000) {
          invitecode = this.inputinvitecode - 100000;
        }
        loginApi
          .register(this.username, this.password, "1", invitecode)
          .then((data) => {
            console.log(data.data, "注册成功");
            storeAction.loginDone(data.data);
          })
          .catch((err) => {
            this.$toast.clear();
            this.$toast.fail(err.msg);
          });
      }
    },

    // 游客注册
    changeTourist() {
      if (this.checked) {
        let username =
          this.$t("youke") + Math.floor(new Date(new Date()).getTime() / 1000);
        loginApi
          .register(username, this.randomCoding(), "2", "")
          .then((data) => {
            console.log(data.data, "游客注册成功");
            storeAction.loginDone(data.data);
          })
          .catch((err) => {
            this.$toast.clear();
            this.$toast.fail(err.msg);
          });
        this.setCookie(username, "", 14);
      } else {
        this.setCookie("", "", -1);
      }
    },
    // 忘记密码
    changePWDho() {
      this.PopAlert = true;
    },
    showAletfalse() {
      this.PopAlert = false;
    },
    changeRouteHome() {
      let that = this;
      storeAction.clearCurrentUserId();
      storeAction.clearToken();
      storeAction.delsessionStorage("id");
      storeAction.delsessionStorage("userId");
      storeAction.delsessionStorage("money");
      storeAction.delsessionStorage("idcard");
      storeAction.delsessionStorage("group_id");
      storeAction.setCurrentUserMoney(null);
      this.$toast.loading({
        message: this.$t("jiazaizhong"),
        forbidClick: true,
        duration: 500,
        onClose() {
          that.$router.push(`/`);
        },
      });
    },
    // 随机生成字母
    randomCoding() {
      //创建26个字母数组
      let arr = [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ];
      let idvalue = "";
      for (let i = 0; i < 6; i++) {
        idvalue += arr[Math.floor(Math.random() * 26)];
      }
      console.log(idvalue, "idvalue");
      return idvalue;
    },
    setCookie(name, pwd, exdays) {
      var exdate = new Date(); // 获取时间
      exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays); // 保存的天数
      // 字符串拼接cookie
      window.document.cookie =
        "userName" + "=" + name + ";path=/;expires=" + exdate.toGMTString();
      window.document.cookie =
        "userPwd" + "=" + pwd + ";path=/;expires=" + exdate.toGMTString();
    },
    // 读取cookie 将用户名和密码回显到input框中
    getCookie() {
      if (document.cookie.length > 0) {
        var arr = document.cookie.split("; "); // 这里显示的格式需要切割一下自己可输出看下
        for (var i = 0; i < arr.length; i++) {
          var arr2 = arr[i].split("="); // 再次切割
          // 判断查找相对应的值
          if (arr2[0] === "userName") {
            this.username = arr2[1]; // 保存到保存数据的地方
            // 其中unescape() 方法是将字符串进行编码，escape()方法是将字符串进行解码。
          } else if (arr2[0] === "userPwd") {
            this.password = arr2[1];
          }
        }
      }
    },
  },
  created() {
    this.getCookie();
    if (localStorage.getItem("localetype") == 1) {
      this.show = 1;
      this.currentLang = "简";
    } else if (localStorage.getItem("localetype") == 2) {
      this.show = 2;
      this.currentLang = "繁";
    } else if (localStorage.getItem("localetype") == 3) {
      this.show = 3;
      this.currentLang = "en";
    }
  },
};
</script>
<style lang="less" scoped>
.psword {
  -webkit-text-security: disc;
}

.lang {
  padding: 0px 7px;
  font-size: 16px;
}

.Login {
  font-family: Arial, Microsoft YaHei, sans-serif;
}

.Login > div.LoginItem {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.Login > div.LoginItem {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.LoginItem {
  height: 100%;
  background: url("../assets/image/bg.jpg") #181a29 no-repeat top;
  background-size: contain;
  font-size: 0.3rem;
  color: #131313;
}

.LoginItem .login_bar {
  position: relative;
  background: none;
  width: 100%;
  height: 1.335rem;
  line-height: 1.335rem;
  text-align: center;
  color: white;
  font-size: 0.36rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.login_bar .back-btn {
  position: absolute;
  top: 0rem;
  left: 0rem;
}

img {
  border: 0 none;
  vertical-align: top;
}

// add

.change_lang {
  position: absolute;
  top: 0.15rem;
  right: 8px;
  font-size: 14px;
}

.change_lang img {
  width: 25px;
}

.LoginItem header {
  width: 100%;
  height: 1.8rem;
  text-align: center;
  position: relative;
  padding-top: 1rem;
}

.LoginItem header .login-logo {
  width: 70%;
  height: auto;
}

.LoginItem content .content-input {
  padding: 0 0.42rem;
}

.content-input .lotteryTitle {
  /* height: 0.68rem; */
  width: 100%;
  /* display: flex; */
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  padding-left: 0.4rem;
  margin: 0 auto;
  margin-top: 0.1rem;
  /* margin-bottom: 0.6rem; */
  padding: 12px 10px;
  /* background: white; */
  /* border: 1px solid rgba(255, 255, 255, 0.35); */
  box-sizing: border-box;
  border-radius: 6px;
  position: relative;
  color: #fff;
}

.lotteryTitle {
  height: auto;
  display: block;
}

.change_lang {
  position: absolute;
  top: 0.15rem;
  right: 8px;
  font-size: 14px;
}

.change_lang img {
  width: 25px;
}

.LoginItem content .content-input .LoginInput {
  margin-bottom: 25px;
}

.content-input .LoginInput {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  // justify-content: center;
  background: #ffffff;
  border-radius: 8px;
  height: 40px;
  border-radius: 25px;
}

.content-input .LoginInput > img {
  margin-left: 13px;
  margin-right: 16px;
  width: 22px;
  height: 22px;
}

button,
input,
select,
textarea {
  font-size: 100%;
  background: none;
  border: none;
  outline: none;
}

input {
  margin: 0;
  padding: 0;
}

input,
button,
textarea {
  color: inherit;
  font: inherit;
}

.LoginItem content .content-input .input-link {
  font-size: 15px;
  color: #999;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.LoginItem .input-link a {
  font-size: 14px;
  color: #e3e3e3;
}

a,
a:visited {
  text-decoration: none;
  color: #4c4743;
}

.login_btn {
  margin-bottom: 0.2rem;
}

.login_btn .btn {
  margin-top: 25px;
  display: block;
  height: 48px;
  line-height: 48px;
  font-size: 18px;
  color: #fff;
  text-align: center;
  background-image: -webkit-gradient(
    linear,
    0% 0,
    0% 100%,
    from(#eee2dd),
    to(#e2d3cd)
  );
  border-radius: 50px;
  border: 0;
  cursor: pointer;
  border: 0;
  width: 100%;
  font-weight: bold;
  box-shadow: 0 0.05rem 0.1rem rgba(0, 0, 0, 0.16);
}

.content-input .login_btn .aftercheck {
  background: #2f3665 !important;
  // background-image: -webkit-gradient(linear, 0% 0, 0% 100%, from(#001722), to(001722)) !important;
}

.login_btn .aftercheck {
  background: #226f83;
  background-image: -webkit-gradient(
    linear,
    0% 0,
    0% 100%,
    from(#226f83),
    to(#226f83)
  );
}

.lang {
  padding: 0px 7px;
  font-size: 16px;
}

.LoginItem content .content-input {
  padding: 0 21px;
}

.LoginItem .content-input {
  // padding: 0 0.42rem;
  // padding: 0 21px;
  /* background: #ffffff4d; */
  height: calc(100% - 4.2rem);
  /* border-top-left-radius: 15px; */
  /* border-top-right-radius: 15px; */
  padding-top: 2.1rem !important;
  margin: 0 15px 50px;
  border-radius: 15px;
  /* box-shadow: 1px 1px 5px 2px rgb(0 0 0 / 20%); */
}

.content-input .LoginInput > input {
  color: #333;
}

.LoginInput > input {
  background: none;
  width: 100%;
  outline: none;
  font-size: 0.32rem;
  border: none;
  height: 80%;
}

input,
select,
button,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-family: inherit;
  appearance: none;
  -moz-appearance: none;
}

.login-body {
  // margin-top: 1.7rem;
  // padding: 0 0.68rem;
}

.van-cell-group {
  background: unset;
  position: unset;

  input {
  }
}

.van-cell {
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  min-height: 40px;
  padding: 0px 16px;
  overflow: hidden;
  color: unset;
  font-size: 14px;
  line-height: 40px;
  background-color: unset;
}

.van-field {
  background-color: #fff;
  color: #000;
  border-radius: 20px;
}

// .van-field::after {
//   position: absolute;
//   box-sizing: border-box;
//   content: " ";
//   pointer-events: none;
//   right: 16px;
//   bottom: 0;
//   left: 16px;
//   border-bottom: 1px solid #3d5179 !important;
//   -webkit-transform: scaleY(0.5);
//   transform: scaleY(0.5);
// }

.left-form-icon {
  width: 20px;
}

.c_a0a0a0 {
  color: #a0a0a0;
}

.Btnlogin {
  color: #fff;
  background-color: #2f3665;
  border: none;
}

.TxtBtn {
  background-color: #2f3665;
  border: none;
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.open-visitor {
  justify-content: space-around;
}

.app-version {
  text-align: center;
  margin-top: 0.4rem;
  color: #a0a0a0;
}

.van-cell::after {
  position: absolute;
  box-sizing: border-box;
  content: " ";
  pointer-events: none;
  right: 16px;
  bottom: 0;
  left: 16px;
  border-bottom: 0px solid #3d5179;
}

.eye-icon {
  margin: 8px 0;
  width: 22px;
  height: 22px;
}

canvas {
  display: block;
  margin: 0;
  padding: 0;
  border: none;
}

.LoginItem .login_bar .lang_cur {
  position: absolute;
  right: 0px;
  top: 0px;
}

#vector1 {
  width: 15px;
  height: auto;
}

#vector2 {
  width: 20px;
  height: auto;
}

.page-title {
  font-size: 18px;
  width: 100%;
  text-align: center;
}

.lang_lump {
  position: absolute;
  width: 40px;
  height: auto;
  text-align: center;
  background: rgb(12 12 12 / 30%);
  border: 0.01rem solid white;
  border-radius: 0.8rem;
  z-index: 99;
  font-size: 0.16rem;
  right: 12px;
  top: 8px;
}

.lang_lump .lang {
  padding: 0 !important;
  font-size: 16px !important;
  height: 40px;
  line-height: 40px;
  color: white;
}

.Register-clause {
  display: flex;
  // justify-content: center;
  align-items: center;
  font-size: 0.28rem;
  color: #b1b1b1;
  /* margin: .4rem 0; */
  font-size: 13px;
}

.Register-clause img {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}
</style>
