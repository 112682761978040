<template>
  <div class="home">
    <div class="Navbar">
      <div class="g-header">
        <div class="navleft">
          <div class="app-line">
            <!-- <span :style="showList ? 'color: #ff7d20;' : ''">{{
              activeList == 0 ? $t("xianlu") + "1" : $t("xianlu") + "2"
            }}</span>
            <van-icon
              name="arrow-down"
              color="#fff"
              size="16px"
              style="margin: 0.2rem 0 0 0.1rem"
              :class="showList ? 'angleRotestow' : 'icon-arrow-bottoms'"
            /> -->
            <img class="avatar" src="../assets/image/auth.png" alt="" />
            <div class="user">
              <span>{{ user.username }}</span>
              <span class="user-bold">{{ user.money }}</span>
            </div>
          </div>
        </div>
        <div class="middle fs-18"></div>
        <div class="navright" @click="changeLanguage">
          <span
            >{{ texType == 0 ? $t("gensuixitong") : $t("jiantizhongwen") }}
          </span>
        </div>
      </div>
      <div class="block"></div>
    </div>
    <div class="g-content flex-column">
      <div class="mescroll">
        <!-- 轮播 -->
        <swi-pe></swi-pe>
        <!-- <div class="broadcast">
          <div class="broadcast-icon">
            <div class="sprite-icon_gonggao"></div>
          </div>
          <div class="c_a0a0a0">{{ $t("dangqianbanben") }}：21.04.02</div>
        </div> -->

        <div class="tips">
          <img src="../assets/image/icon_ring.png" />
          <div style="overflow: hidden">
            <section class="tips-content" style="width: 323px">
              <div class="dec" style="width: 323px">
                <marquee scrollamount="3">
                  {{ $t("公告") }}
                </marquee>
              </div>
            </section>
          </div>
        </div>

        <div class="menu-container"></div>
        <div class="title">
          {{ $t("guanfangtuijian") }}
        </div>
        <!-- 横向滑动 -->
        <lt-list></lt-list>
        <!-- 资产 -->
        <!-- <div class="cont-head">
          <div class="cont-left">
            <div>
              <div class="en-text">{{ $t("wodezichan") }}</div>
              <div class="left-text">
                {{ money ? money : $t("dengluhouchakan") }}
              </div>
            </div>
            <img src="@/assets/image/zichan.png" class="icon" alt="" />
          </div>
          <div class="cont-right" @click="changeNews">
            <div class="huobin">
              <img src="@/assets/image/touzi.png" class="rightcion" alt="" />
              <div>{{ $t("touzikuaixun") }}</div>
            </div>
          </div>
        </div> -->

        <!-- <div class="title">
					{{ $t("yonghudongtai") }}
				</div> -->
        <!---横线块无颜色 -->
        <!-- <div class="menu-container"></div> -->
        <!-- 跑马灯 -->
        <!-- <div class="prize-news-wrap">
					<div class="sub-title">
						<span>{{ $t("jiaoyiyonghu") }}</span>
						<span>{{ $t("jiaoyipinglei") }}</span>
						<span>{{ $t("danlunhuoli") }}</span>
						<span>{{ $t("jiaoyiriqi") }}</span>
					</div>
					<div class="prize-news">
						<vue-seamless-scroll class="vux-marquee prize-marquee" :class-option="classOption"
							:data="infoList">
							<ul class="vux-marquee-box">
								<li class="prize-marquee-item" v-for="(item, index) in infoList" :key="index">
									<span class="name">{{ item.nickname }}</span>
									<span class="type">{{ item.game_name }}</span>
									<span class="money">{{ item.profit }}</span>
									<span class="date">{{ time }}</span>
								</li>
							</ul>
						</vue-seamless-scroll>
					</div>
				</div> -->

        <div class="title">
          {{ $t("yonghudongtai") }}
        </div>

        <div class="pdlr bgWhite mt">
          <div class="dynamic-wrap">
            <div class="dynamic clearfix">
              <div class="dot-line-wrap">
                <div class="dot-line"><span></span></div>
                <div class="dot-line"><span></span></div>
                <div class="dot-line"><span></span></div>
                <div class="dot-line"><span></span></div>
                <div class="dot-line"><span></span></div>
              </div>
              <div class="list-wrap">
                <ul
                  class="scroll-list"
                  ref="listWrap"
                  :style="{ marginTop: kkss + 'px' }"
                >
                  <li v-for="(item, index) in randomList" :key="index">
                    <span class="text-blue list-name">{{ item.username }}</span>
                    <span class="list-lottery"
                      >{{ $t(item.product) }}<em class="text-red"></em
                    ></span>
                    <span class="text-gray list-addtime">{{
                      item.amount
                    }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="bot-nav"></div>
        <!-- <p class="vux-divider home-divider">亲，我是有底线的</p> -->
      </div>
    </div>
    <tab-bar></tab-bar>
    <!-- 线路遮罩层 -->
    <van-overlay v-model:show="showList" @click="showList = false">
      <div class="content">
        <ul class="line-content">
          <li class="line-item boderBottom" @click="activechange(0)">
            <p>{{ $t("xianlu") }}1</p>
            <img
              src="@/assets/image/gou.png"
              class="icon"
              alt=""
              v-if="activeList == 0"
            />
          </li>
          <li class="line-item boderBottom" @click="activechange(1)">
            <p>
              {{ $t("xianlu") }}2
              <span style="color: green">({{ $t("zuiyou") }})</span>
            </p>
            <img
              src="@/assets/image/gou.png"
              class="icon"
              alt=""
              v-if="activeList == 1"
            />
          </li>
        </ul>
      </div>
    </van-overlay>
  </div>
</template>

<script>
// @ is an alias to /src
import TabBar from "@/components/tabbar/TabBar.vue";
import LtList from "@/components/ltlist/LtList.vue";
import SwiPe from "@/components/swipe/SwiPe.vue";
import homeApi from "@/api/home";
import vueSeamlessScroll from "vue-seamless-scroll/src";
import storeAction from "@/store/typed-actions";
import userApi from "@/api/user";
export default {
  name: "Home",
  components: {
    TabBar,
    SwiPe,
    LtList,
    vueSeamlessScroll,
  },
  computed: {
    classOption() {
      return {
        step: 0.2, // 数值越大速度滚动越快
        // limitMoveNum: 10, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        // openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
    money() {
      console.log(this.$store.state.money);
      return this.$store.state.money;
    },
    activeList() {
      console.log(this.$store.state.activeList);
      return this.$store.state.activeList;
    },
  },
  data() {
    return {
      showList: false,
      infoList: [],
      isShow: true,
      texType: localStorage.getItem("localetype"),
      time: "",
      user: "",
      randomList: [], // 存储随机生成的数据
      kkss: 0,
    };
  },
  created() {
    let myDate = new Date();
    let time = myDate.toLocaleDateString();
    this.time = time.slice(5);
    this.info();
    this.onRefresh();

    this.generateRandomList();
    this.$nextTick(() => {
      // 在 DOM 更新完毕后执行滚动动画
      this.startScrollAnimation();
    });
  },
  mounted() {},
  methods: {
    // 开始动画
    // 开始动画
    // 开始滚动动画
    // 开始滚动动画
    startScrollAnimation() {
      const listWrap = this.$refs.listWrap;

      // 确保listWrap存在
      if (listWrap) {
        const totalHeight = listWrap.scrollHeight; // 获取整个列表的总高度
        let n = 0; // 用于记录当前的滚动步数

        // 滚动动画
        let scrollInterval = setInterval(() => {
          const stepHeight = 1; // 每次移动1px，控制滚动的速度
          let currentMarginTop =
            parseInt(getComputedStyle(listWrap).marginTop) || 0;

          // 如果当前滚动的距离还没到达最大高度，则继续滚动
          if (
            Math.abs(currentMarginTop) <
            totalHeight - listWrap.parentElement.clientHeight
          ) {
            // 更新margin-top
            n += stepHeight;
            listWrap.style.marginTop = `-${n}px`;
          } else {
            // 滚动到末尾时，重置到顶部
            listWrap.style.transition = "none"; // 取消平滑动画
            listWrap.style.marginTop = "0px"; // 直接重置
            n = 0; // 重新开始
          }

          // 恢复平滑动画
          setTimeout(() => {
            listWrap.style.transition = "margin-top 0.1s linear"; // 恢复平滑动画
          }, 50);
        }, 30); // 每30ms移动一次，控制滚动速度
      }
    },
    // 生成随机2位字母前缀
    generateRandomPrefix() {
      const letters = "abcdefghijklmnopqrstuvwxyz";
      const randomChar1 = letters.charAt(
        Math.floor(Math.random() * letters.length)
      ); // 随机第一个字母
      const randomChar2 = letters.charAt(
        Math.floor(Math.random() * letters.length)
      ); // 随机第二个字母
      return randomChar1 + randomChar2;
    },
    // 生成随机用户名
    generateRandomUsername() {
      const prefix = this.generateRandomPrefix() + "***"; // 生成随机字母前缀并加上 ***
      const randomNum = Math.floor(Math.random() * 90 + 10); // 生成10到99之间的随机数
      return `${prefix}${randomNum}`;
    },
    // 生成随机产品名称
    generateRandomProduct() {
      const products = ["短期理财", "长期理财"];
      const randomIndex = Math.floor(Math.random() * products.length);
      return products[randomIndex];
    },
    // 生成随机金额
    generateRandomAmount() {
      const amount = Math.floor(Math.random() * 10000 + 1000); // 生成1000到10000之间的随机金额
      return `**${amount}`;
    },
    // 生成随机列表项
    generateRandomList() {
      const listSize = 100; // 生成10个随机条目
      for (let i = 0; i < listSize; i++) {
        this.randomList.push({
          username: this.generateRandomUsername(),
          product: this.generateRandomProduct(),
          amount: this.generateRandomAmount(),
        });
      }
    },
    onRefresh() {
      userApi.userInfo().then((data) => {
        this.user = data.msg;
        // storeAction.setCurrentUserMoney(data.msg.money);
        // storeAction.setCurrentUserGroup_id(data.msg.group_id);
        // storeAction.setsessionStorage("money", data.msg.money);
        // storeAction.setsessionStorage("group_id", data.msg.group_id);
        // storeAction.setsessionStorage("idcard", data.msg.idcard);
        // storeAction.setCurrentUserusercrud(data.msg.idcard);
      });
    },
    changeLanguage() {
      this.$router.push({
        path: "/language",
      });
    },
    activechange(active) {
      this.$toast.loading({
        message: this.$t("jiazaizhong"),
        forbidClick: true,
        duration: 1000,
      });
      storeAction.setCurrentUseractiveList(active);
      storeAction.setsessionStorage("activeList", active);
      console.log(active);
    },
    info() {
      this.$toast.loading({
        message: this.$t("jiazaizhong"),
        forbidClick: true,
        duration: 0,
      });
    },
    changeNews() {
      this.$router.push({
        path: "/moreNews",
      });
    },
  },
};
</script>
<style lang="less" scoped>
.home {
  height: 100%;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.g-header {
  position: absolute;
  width: 100%;
  height: 1.18rem;
  text-align: center;
  // background: red;
  background: rgb(14, 21, 48);
  z-index: 9999;
  font-size: 0.35rem;
  color: #fff;
}

.block {
  height: 1.18rem;
}

.navleft,
.navright {
  display: flex;
  z-index: 11;
  position: absolute;
  height: 100%;
  align-items: center;
}

.avatar {
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
}

.user {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-left: 0.2rem;
}

.user-bold {
  font-weight: bold;
}

.middle {
  z-index: 10;
  max-width: 80%;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.fs-18 {
  font-size: 0.36rem;
}

.navright {
  right: 0.2rem;
}

.app-line {
  display: flex;
  align-items: center;
  padding: 0 0.2rem;
}

.broadcast {
  min-height: 30px;
  padding: 0.2rem 0.4rem;
  display: flex;
  align-items: center;
}

.broadcast-icon {
  margin-right: 0.2rem;
}

.sprite-icon_gonggao {
  background: url("../assets/image/duo.png");
  width: 0.49rem;
  height: 0.49rem;
  background-size: 2.53913043rem 2.3026087rem;
  background-position: -1rem 0.9rem;
}

.c_a0a0a0 {
  color: #a0a0a0;
}

.cont-head {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin-top: 0.2rem;
  padding: 0 0.2rem;
}

.cont-left {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.3rem;
  padding: 0.6rem 0.2rem;
  color: #fff;
  background: #1a243f;
  border-radius: 4px;
}

.en-text {
  text-align: left;
  font-size: 0.37rem;
}

.left-text {
  padding-top: 0.22rem;
  color: #999;
  font-size: 0.35rem;
}

.icon {
  margin-left: 0.4rem;
  width: 1.29rem;
  height: 1.32rem;
}

.cont-right {
  width: 50%;
}

.huobin {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.28rem;
  background: #1a243f;
  border-radius: 4px;
  color: #fff;
  font-size: 0.37rem;
}

.rightcion {
  width: 0.77rem;
  height: 0.65rem;
  margin-right: 0.2rem;
}

.menu-container {
  display: flex;
  justify-content: space-between;
  margin-top: 0.2rem;
  padding: 0 0.2rem;
}

.prize-news-wrap {
  padding: 0 0.2rem 50px;
  margin-top: 0.2rem;
}

.sub-title {
  display: flex;
  text-align: center;
  font-size: 14px;
  color: #afafaf;

  span {
    flex: 1;
    padding: 0.1rem 0 0.2rem;
  }
}

.prize-news {
  height: 150px;
}

.prize-marquee {
  height: 150px !important;
  font-size: 14px;
}

.vux-marquee {
  width: 100%;
  overflow: hidden;
  height: 300px;
}

.vux-marquee-box {
  padding: 0;
  margin: 0;
  width: 100%;
  height: auto;
}

.prize-marquee-item {
  height: 30px;
  line-height: 30px;
  display: flex;
  align-items: center;
  -ms-flex-pack: distribute;
  justify-content: space-around;

  span {
    display: inline-block;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
  }

  .name,
  .money {
    color: #b99318;
  }

  .type,
  .date {
    color: #fff;
  }
}

.content {
  position: absolute;
  width: 100%;
  background: #1a243f;
  top: 1.18rem;
  z-index: 12;
}

.line-content {
  background-color: #040925;
}

.line-content .line-item {
  height: 1rem;
  font-size: 0.35rem;
  padding: 0 0.4rem 0 0.2rem;
  display: flex;
  align-items: center;
}

.line-item :after {
  content: " ";
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 1px;
  border-bottom: 1px solid #3d5179;
  color: #3d5179;
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
  -webkit-transform: scaleY(0.5);
  transform: scaleY(0.5);
}

.line-item .icon {
  margin-left: auto;
  width: 0.6rem;
  height: 0.6rem;
}

.angleRotestow {
  transform: rotate(180deg);
  transition: transform 0.3s, -webkit-transform 0.3s;
}

.icon-arrow-bottoms {
  transform: rotate(0);
  transition: transform 0.3s;
}

.title {
  position: relative;
  padding: 0 0.3rem;
  margin: 0.2rem;
  margin-bottom: 0.3rem;
  font-size: 0.4rem;
  font-weight: bold;
  text-align: left;

  &::before {
    position: absolute;
    content: "";
    left: 0.1rem;
    height: 100%;
    width: 3px;
    background-color: #5e76ff;
  }
}

//
.tips {
  width: 100%;
  height: 30px;
  /* border-radius: .6rem; */
  position: relative;
  font-size: 13px;
  line-height: 30px;
  color: #95979c;
  letter-spacing: -0.18px;
  background: white;
  /* border: 1px solid #d7b68d; */
  /* left: 0; */
  /* right: 0; */
  /* margin: 0 .22rem; */
  /* margin: .1rem auto .1rem; */
  padding: 0 0 0 30px;
  /* top: 4.2rem; */
  text-align: center;
  z-index: 10;
  overflow: hidden;
}

.tips img {
  position: absolute;
  left: 5px;
  top: 0.1rem;
  height: 20px;
  width: 20px;
}

.bgWhite {
  background: #fff !important;
}

.mt {
  margin-top: 8px !important;
}

.pdlr {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.dynamic-wrap {
  padding: 8px 0;
}

.dynamic {
  background: #f8f8f8;
}

.dynamic .dot-line-wrap {
  float: left;
}

.dynamic .dot-line {
  position: relative;
  margin: 0 10px;
  width: 6px;
  height: 40px;
}

.dynamic .dot-line:after {
  content: " ";
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -6px;
  width: 6px;
  height: 6px;
  background: #3f51b5;
  border-radius: 50%;
}

.dynamic .dot-line span:after,
.dynamic .dot-line span:before {
  content: " ";
  position: absolute;
  left: 0.053333rem;
  width: 1px;
  height: 50%;
  background: rgb(35 125 148 / 22%);
}

.dynamic .dot-line span:before {
  top: 0;
}

.dynamic .dot-line span:after {
  bottom: 0;
}

.dynamic .dot-line:first-child span:before,
.dynamic .dot-line:last-child span:after {
  display: none;
}

.dynamic .list-wrap {
  margin-left: 28px;
  height: 200px;
  overflow: hidden;
}

.dynamic .list-wrap .anim {
  transition: all 0.5s;
}

.dynamic li {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: auto;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.dynamic li .list-name {
  width: 72px;
  flex: 0 0 72px;
}

.dynamic li span {
  height: 39px;
  line-height: 39px;
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.dynamic li .list-lottery {
  /*-ms-flex: 1;*/
  /*flex: 1*/
}

.dynamic li .list-addtime {
  width: 2.4rem;
  flex: 0 0 2.4rem;
}

.dynamic li em {
  margin: 0 11px;
}

.bot-nav {
  margin-top: 16px;
  text-align: center;
}

.home-divider {
  color: #999;
  padding: 0rem 25px !important;
  margin-bottom: 60px;
}

.vux-divider {
  display: table;
  white-space: nowrap;
  height: auto;
  overflow: hidden;
  text-align: center;
  margin-bottom: 50px;
}

.bot-nav {
  margin-top: 16px;
  text-align: center;
}

.vux-divider:after,
.vux-divider:before {
  content: "";
  display: table-cell;
  position: relative;
  top: 50%;
  width: 50%;
  background-repeat: no-repeat;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABaAAAAACCAYAAACuTHuKAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDE0IDc5LjE1MTQ4MSwgMjAxMy8wMy8xMy0xMjowOToxNSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo1OThBRDY4OUNDMTYxMUU0OUE3NUVGOEJDMzMzMjE2NyIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo1OThBRDY4QUNDMTYxMUU0OUE3NUVGOEJDMzMzMjE2NyI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjU5OEFENjg3Q0MxNjExRTQ5QTc1RUY4QkMzMzMyMTY3IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjU5OEFENjg4Q0MxNjExRTQ5QTc1RUY4QkMzMzMyMTY3Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+VU513gAAADVJREFUeNrs0DENACAQBDBIWLGBJQby/mUcJn5sJXQmOQMAAAAAAJqt+2prAAAAAACg2xdgANk6BEVuJgyMAAAAAElFTkSuQmCC);
}

.vux-divider:after,
.vux-divider:before {
  background-position: right 1em top 50%;
}
</style>
