<template>
  <div class="container">
    <iframe :src="url" frameborder="0" width="100%" height="100%"></iframe>
  </div>
</template>

<script>
export default {
  name: "Iframe",
  data() {
    return {
      url: "https://chatlink.wchatlink.com/widget/standalone.html?eid=0a8dec31ced8970e43de7487240ee067&language=",
    };
  },
};
</script>

<style lang="less" scoped>
.container {
  width: 100vw;
  height: 100vh;
}
</style>
