const jp = {
  jiantizhongwen: '日本語',
  xianlu: 'ライン',
  dianwei: '現在の地点',
  wodezichan: '私の資産',
  touzikuaixun: '投資ニュース',
  jiaoyiyonghu: '取引ユーザー',
  jiaoyipinglei: '取引カテゴリー',
  danlunhuoli: '1ラウンドで利益を得る',
  jiaoyiriqi: '取引日',
  dengluhouchakan: 'ログイン後に見る',
  shouye: 'フロントページ',
  jiaoyidating: 'トレーディングフロア',
  yucun: '事前保存',
  zoushi: '傾向',
  wode: '私の',
  kefu: '顧客サービス',
  zuiditouzi: '最低投資額',
  zuidijinru: '最低エントリー',
  jichulicai: '基本的な財務管理',
  zishenlicai: '上級財務管理者',
  dashilicai: 'マスター財務管理',
  zhuanjialicai: '専門家による財務管理',
  基础理财: '基本的な財務管理',
  资深理财: '上級財務管理者',
  大师理财: 'マスター財務管理',
  专家理财: '専門家による財務管理',
  huiyuanquanyi: '会員の権利',
  zuoriyingli: '昨日の利益',
  zongzichan: '元宝',
  chucunjin: 'デポジット',
  jinriyingli: '今日の利益',
  zhuanchu: '現金を引き出す',
  cunru: '補充する',
  zhanghuxinxni: 'アカウント情報',
  lishizhangdan: '取引履歴',
  zijinminxi: 'ファンドの詳細',
  renwuzhongxin: 'ミッションセンター',
  xiaoxigonggao: 'ニュース発表',
  tuijianfenxiang: '共有を推奨する',
  huodongzhongxin: 'アクティビティセンター',
  jibenziliao: '基本情報',
  dengluzhanghao: 'ログインアカウント',
  nicheng: 'ニックネーム',
  shezhi: '設定',
  genggaixianlu: 'ルート変更',
  guanyuwomen: '私たちについて',
  xitongshezhi: 'システム設定',
  yuyanshezhi: '言語設定',
  tuichudenglu: 'ログアウト',
  bangdingyinhangka: '銀行カードをバインドする',
  bangdingxuniqianbao: '仮想ウォレットをバインドする',
  anquanma: 'セキュリティコード',
  xiugailenglumima: 'ログインパスワードを変更する',
  yinhangkaguanli: '銀行カード',
  tianjiayinhangka: '銀行カードを追加する',
  xuniqianbaoguanli: 'USDT',
  tianjiaxuniqianbao: '仮想ウォレットを追加する',
  chiakren: 'カード所有者',
  yinhangkakahao: '銀行カード番号',
  yinhangmingcheng: '銀行名',
  yhkTps: 'カード所有者のキャッシュカードをバインドしてください',
  nixiqianbaodizhi: '仮想ウォレットアドレス',
  xuniqianbaoleixing: '仮想ウォレットの種類',
  xnqbTps: ' 温ヒント: USDT アドレス タイプを入力してください (例: trc20、erc20、omni) ',
  plchikaren: 'カード所有者を入力してください',
  plkahao: 'カード所有者のカード番号を入力してください',
  plyinhangmingcheng: '銀行名を入力してください',
  plqbdz: '仮想ウォレットのアドレスを入力してください',
  plqblx: '仮想ウォレットのタイプを入力してください',
  shezhianquanma: 'セキュリティコードを設定する',
  yuananquanma: 'オリジナルのセキュリティコード',
  xinanquanma: '新しいセキュリティコード',
  planquanma: '元のセキュリティコードを入力してください',
  plxinanquanma: '新しいセキュリティコードを入力してください',
  queren: '確認する',
  quxiao: 'キャンセル',
  anquanmaTps: ' セキュリティコードを設定してください。キャッシュカードのパスワードと同じであってはなりません。',
  anquanmaTpsTow: ' お客様各位、このセキュリティ コードは転送時のパスワードです。セキュリティ上の理由から、ログイン パスワードと同じにならないようにしてください。',
  xiugaidenglu: 'パスワードを変更する',
  jiumima: '以前のパスワード',
  xinmima: '新しいパスワード',
  querenmima: 'パスワードを認証する',
  pljiumima: '古いパスワードを入力してください',
  plxinmima: '新しいパスワードを入力してください',
  plquerenmima: 'パスワードをもう一度入力してください',
  wancheng: '仕上げる',
  kaishitouzi: '投資を始める',
  kaiTps: '現在の回線が利用できない場合は、別の回線に切り替えてみてください',
  zuiyou: '最適な',
  dangaqianxianlu: '現在の行',
  dangqianbanben: '現在のバージョン',
  banquansuoyou: '無断転載を禁じます',
  yinglizhendong: '収益ショック',
  gensuixitong: 'システムに従ってください',
  zhuanchujine: '振込金額',
  plzhuanchu: '送金額を入力してください',
  zhuanchuTps: '暖かいリマインダー: ご質問がある場合は、プラットフォームのカスタマー サービスにお問い合わせください。',
  xuanzeyh: '引き出し用の銀行カードを選択してください',
  xuanzehb: '出金用の仮想ウォレットを選択してください',
  tijiaoshenqing: '申請書を提出する',
  shuruanquanma: 'セキュリティコードを入力してください',
  xianshangcunru: 'オンラインで入金',
  saomacunru: 'USDT',
  wangyincunru: "銀行預金",
  shijian: '時間',
  upshijian: '開始時間',
  downshijian: '終了時間',
  wuTps: '関連するデータはまだありません~',
  jiazaicx: 'リロード',
  di: 'いいえ。',
  lun: '車輪',
  weiyingli: '儲からない',
  yishouli: '承認されました',
  zhuangtai: '州',
  chongzhi: 'リセット',
  riqi: '日付',
  CapActive: {
    chongzhi: '補充する',
    tixian: '現金を引き出す',
    goumai: '買う',
    yingli: '利益',
    zengsong: '譲る',
    weitongguo: '失敗した',
    yitongguo: '合格した',
    yijujue: '拒否されました',
  },
  zhanneixiaoxi: "サイトニュース",
  pingtaigonggao: 'プラットフォームの発表',
  fuzhilanjie: "リンクをコピー",
  denglu: 'ログイン',
  zhuce: '登録する',
  jizhumima: 'パスワードを記憶する',
  wangjimima: 'パスワードを忘れる',
  youkedenglu: '訪問者ログイン',
  zhucezhanghao: "アカウントを登録する",
  plusername: 'ユーザー名を入力してください',
  plpassword: 'パスワードを入力してください',
  wanchengzhuce: '登録を完了する',
  yiyouzhanghao: 'すでにアカウントをお持ちです',
  qingdenglu: 'ログインしてください',
  remenhuati: 'ホットトピック',
  news1: 'オリジナル',
  news2: '推薦する',
  news3: '富',
  renliulan: '人々が閲覧する',
  hezhi: '合計値',
  lunshu: 'ラウンド数',

  大: '大きい',
  小: '小さい',
  大单: '大きな注文',
  大双: 'ダブル',
  小单: '少量の注文',
  小双: '小双',
  单: '1つ',
  双: 'ペア',
  极小: '非常に小さい',
  极大: '素晴らしい',
  qi: '期待する',
  juli: '距離',
  lunjiezhi: 'ラウンド終了',
  yifengpan: '閉店',
  yiguanbi: '閉店',
  yonghu: 'ユーザー',
  danlunjiaoyi: 'シングルラウンドトランザクション',
  caozuo: '操作する',
  pljoin: 'チャット ルームに正常に参加しました',
  pltingshou: '製品は製造中止となりました',
  gengou: "フォローアップ購入",
  quedinggengou: "購入をフォローしてもよろしいですか?",
  wanjia: "プレーヤー",
  leibie: "カテゴリ",
  jine: "額",
  gouru: 'ベット',
  zanweigouru: 'まだ購入していません',
  qigou: '最低購入金額',
  gourushuliang: '購入数量',
  changgui: '従来の',
  shuzi: '番号',
  yinglihezhi: '利益と価値',
  shouyijieshao: '収入紹介',
  lijicunru: '今すぐ入金',
  kaihaojieguo: 'ナンバーオープニング結果',
  kaihaoqushi: 'オープニングトレンド',
  chanpingshuoming: '製品説明',
  qingchuhuancun: 'キャッシュをクリアする',
  youke: '観光客',
  gongxi: 'おめでとう ',
  zai: ' 存在する ',
  yingli: ' 利益 ',
  xiadanchenggong: '注文は正常に完了しました',
  jiazaizhong: '読み込み中...',
  guanbitg: '閉店、購入停止',
  xiangoudl: '現在の購入ラウンドのみをフォローできます',
  liaotiantip: 'システムにより禁止されています。購入することを選択してください',
  tishi: 'ヒント',
  zhidao: '知っていた',
  zhuanchusb: '転送に失敗しました',
  zhuanchusbs: '残高不足のため送金できませんでした',
  buyxq: '購入詳細',
  orderNo: '請求書番号',
  plan_name: 'お部屋のご案内',
  buyxx: '購入情報',
  haoxx: '番号選択の詳細',
  buysj: '購入時間',
  yilou: '省略',
  fzjqb: 'ペーストボードにコピーされました',
  chanpin1: '販売開始時間：3分半に1周、同日午前10時開店、翌日午前2時閉店。',
  chanpin2: '各ラウンドには 0 から 9 までの 3 つの数字があり、その 3 つの数字が最終結果に追加され、ロング、ショート、フラット、シングル、フラット、バイポーラ、アノード、カソードと数字になります。',
  Tips: {
    wjmm: 'パスワードを忘れた場合は、カスタマーサービスに連絡して変更してください。!',
    dlcg: 'ログイン成功',
    tjcg: '送信成功',
    zanwu: 'まだデータがありません',
    buyizhi: '2 つのパスワードが矛盾しています',
    zcbz: '十分な資産がありません',
    pltxje: '出金額を入力してください',
    zdtx: '最低出金額 100',
    youke: 'ご来場の皆様へ 正式会員登録を行ってからお進みください。',
    szcg: 'セットアップ成功',
  },
  共1注: '合計1ノート',
  wallet: '財布',
  guanfangtuijian: '公式推奨',
  yonghudongtai: 'ユーザーニュース',
  lijijinru: '今すぐ入力してください',
  lijizhuli: '今すぐ助けてください',
  zztzz: '賭けが進行中',
  "立即登录": "今すぐログインしてください",
  "混合": "ミックス",
  "特码": "特別なコード",
  "玩法": "遊び方",
  "游戏规则": "ゲームのルール",
  "公告": 'お客様へ、平素よりお世話になっております。プラットフォームは夜間の業務を停止しておりますので、毎日12:00～23:30の間にカスタマーサービスまでご連絡ください。ご支持いただき、ありがとうございます。お客様の生活が楽しいものでありますように！',
  "短期理财": "短期的な財務管理",
  "长期理财": '長期的な財務管理',
  "初级VIP": "ジュニアVIP",
  "中级VIP": "中級VIP",
  "高级VIP": "プレミアムVIP",
  "至尊VIP": "最高のVIP",
  "大": "大きい",
  "小": "小さい",
  "单": "1つ",
  "双": "ペア",
  "大单": "大きな注文",
  "大双": "ダブル",
  "小单": "少量の注文",
  "小双": "小双",
  "极小": "非常に小さい",
  "极大": "素晴らしい",
  "已选择": "選択済み",
  "注": "注記",
  "账户充值": "アカウントのリチャージ",
  "充值金额": "チャージ金額",
  "最低充值金额": "最低チャージ金額",
  "到帐账号": "預金口座番号",
  "支付方式": "支払方法",
  "充值汇率": "リチャージ為替レート",
  "请确认您的账号。最低充值金额为": "アカウントを確認してください。最低チャージ金額は、",
  "请按照上面提供的支付方式选择进行支付": "上記のお支払い方法に従ってお支払いください。",
  "支付时，请备注您支付的订单号提交审核。提交成功后，请等待到帐确认": "支払いの際は、支払った注文番号をメモし、確認のために送信してください。送信が完了したら、支払いの確認をお待ちください",
  "资金变动": "基金の変更",
  "审核中": "検討中",
  "已通过": "合格した",
  "已拒绝": "拒否されました",
  "客服中心": "カスタマーサービスセンター",
  "在线客服": "オンラインカスタマーサービス",
  "如何充值？": "補充方法は？",
  "联系客服，银行卡转账": "カスタマーサービスにお問い合わせください、銀行カード振込",
  "忘记了密码怎么办？": "パスワードを忘れた場合はどうすればよいですか?",
  "请及时提供相应信息给客服，系统将重置一个新密码，为保障账户安全，建议用户登录后立即修改密码。": "対応する情報をカスタマー サービスにタイムリーに提供してください。システムは新しいパスワードをリセットします。アカウントのセキュリティを確保するため、ユーザーはログイン後すぐにパスワードを変更することをお勧めします。 ",
  "如何开通网上支付？": "オンライン支払いを有効にするにはどうすればよいですか?",
  "开通银行卡网上支付功能，包括网上开通和现场开通两种方式：": "銀行カードのオンライン支払い機能を有効化するには、オンライン アクティベーションとオンサイト アクティベーションの 2 つの方法があります。",
  "（1）网上开通：登录各大银行的官方网站在线开通。": "（1）オンラインアクティベーション：主要銀行の公式 Web サイトにログインしてオンラインでアクティベートします。",
  "（2）现场开通：前往各大银行的营业网点现场开通。": "（2）オンサイトアクティベーション：大手銀行の営業店に出向き、現地で開設。",
  "提款次数有没有限制？": "出金回数に制限はありますか？",
  "本公司不限制提款次数。": "当社は引き出し回数に制限を設けていません。",
  "提款需要手续费吗？": "出金に手数料はかかりますか?",
  "达到提款条件才能提款，提款不限次数，手续费根据本站设定。提款时产生的银行手续费将全部由本站为您承担。": "出金条件を満たした場合のみ出金可能であり、手数料は本サイトに準じて設定されております。資金を引き出す際に発生する銀行手数料はすべて当サイトが負担します。",
  "邀请码": "招待コード",
  "欢迎光临": "いらっしゃいませ",
  "马上注册": "今すぐ登録してください",
  "请输入邀请码": "招待コードを入力してください",
  "请输入验证码": "確認コードを入力してください",
  "我已年满18周岁且同意接受": "私は 18 歳以上であり、同意することに同意します",
  "立即登陆": "今すぐログインしてください",
  "恭喜中奖了": "受賞おめでとうございます",
  "太棒了！恭喜获得！": "素晴らしい！おめでとう！",
  "和值竞猜游戏，依照福利彩兼发行中心统一发行的竞猜游戏 彩票开奖为依据所规划的线上彩票游戏，完全公开、公平、公正、开奖透明，无法作弊": " 金額当てゲームは、福祉宝くじ流通センターが発行する抽選結果に基づいて企画されたオンライン宝くじゲームであり、完全にオープン、公平、公正、透明であり、不正行為はできません。",
  "竞猜游戏实行自愿购买，凡购买者均被视为同意并遵守本规则": "推測ゲームは任意の購入であり、すべての購入者はこれらの規則に同意し、遵守するものとみなされます。",
  "游戏介绍": "ゲーム紹介",
  "游戏玩法": "ゲームプレイ",
  "将叁个数值进行相加后所得结果，即为和值": "3 つの値を加算した結果が合計になります。",
  "温馨提示": "親切なヒント",
  "平台禁止在同一期反向购买两注相同金额（即大、小或者单、双），此操作属于违法行为，一经发现，将冻结其账号": " プラットフォームは、同じ期間内に同じ金額（つまり、大、小、奇数、または偶数）の 2 つの賭けを逆購入することを禁止しており、この操作は違法であり、発見されるとアカウントが凍結されます。 ",
  "平台每日提现需完成账户余额一倍打码量，没有完成当日打码量的，次日零点清零重新计算": " プラットフォームの毎日の現金引き出しは、口座残高の2倍のコーディング額を完了する必要があります。当日にコーディング額が完了しない場合、翌日の0時にリセットされ、再計算されます。 ",
  "保存": "保存",
  "更改头像": "アバターの変更",
  "身份证号码": "ID番号",
  "请输入身份证号码": "ID番号を入力してください",
  "更改头像": "アバターの変更",
  "身份证号码": "ID番号",
  "请输入身份证号码": "ID番号を入力してください",
  "从相册选择": "アルバムから選ぶ",
  "拍照": "写真",
  "系统默认图片": "システムのデフォルト画像",
  "冻结金额": "凍結金額",
  "提现资金不足": "出金額が不足しています",
  "您的资产不足请存入": "資産が不足していますので、入金してください",
  "我的团队": "私のチーム",
  "用户名": "ユーザー名",
  "余额": "残高",
  "类型": "タイプ",
  "做多": "買い",
  "做空": "売り",
  "平单": "決済",
  "平双": "両建て決済",
  "姓名": "名前",
  "手机号": "電話番号",
  "流水必須達到": "流水は",
}
export default jp;