const zh = {
    jiantizhongwen: '简体中文',
    xianlu: '线路',
    dianwei: '当前点位',
    wodezichan: '我的资产',
    touzikuaixun: '投资快讯',
    jiaoyiyonghu: '交易用户',
    jiaoyipinglei: '交易品类',
    danlunhuoli: '单轮获利',
    jiaoyiriqi: '交易日期',
    dengluhouchakan: '登陆后查看',
    shouye: '首页',
    jiaoyidating: '交易大厅',
    yucun: '预存',
    zoushi: '走势',
    wode: '我的',
    kefu: '客服',
    zuiditouzi: '最低投资',
    zuidijinru: '最低进入',
    jichulicai: '基础理财',
    zishenlicai: '资深理财',
    dashilicai: '大师理财',
    zhuanjialicai: '专家理财',
    基础理财: '基础理财',
    资深理财: '资深理财',
    大师理财: '大师理财',
    专家理财: '专家理财',
    huiyuanquanyi: '会员权益',
    zuoriyingli: '昨日盈利',
    zongzichan: '元宝',
    chucunjin: '储存金',
    jinriyingli: '今日盈利',
    zhuanchu: '提现',
    cunru: '充值',
    zhanghuxinxni: '账户信息',
    lishizhangdan: '交易记录',
    zijinminxi: '资金明细',
    renwuzhongxin: '任务中心',
    xiaoxigonggao: '消息公告',
    tuijianfenxiang: '推荐分享',
    huodongzhongxin: '活动中心',
    jibenziliao: '基本资料',
    dengluzhanghao: '登录账号',
    nicheng: '昵称',
    shezhi: '设置',
    genggaixianlu: '更改线路',
    guanyuwomen: '关于我们',
    xitongshezhi: '系统设置',
    yuyanshezhi: '语言设置',
    tuichudenglu: '退出登录',
    bangdingyinhangka: '绑定银行卡',
    bangdingxuniqianbao: '绑定虚拟钱包',
    anquanma: '安全码',
    xiugailenglumima: '修改登陆密码',
    yinhangkaguanli: '银行卡',
    tianjiayinhangka: '添加银行卡',
    xuniqianbaoguanli: 'USDT',
    tianjiaxuniqianbao: '添加虚拟钱包',
    chiakren: '持卡人',
    yinhangkakahao: '银行卡卡号',
    yinhangmingcheng: '银行名称',
    yhkTps: '请绑定持卡人本人的银行卡',
    nixiqianbaodizhi: '虚拟钱包地址',
    xuniqianbaoleixing: '虚拟钱包类型',
    xnqbTps: ' 温馨提示：请填写USDT地址类型，如：trc20、erc20、omni ',
    plchikaren: '请输入持卡人',
    plkahao: '请输入持卡人卡号',
    plyinhangmingcheng: '请输入银行名称',
    plqbdz: '请输入虚拟钱包地址',
    plqblx: '请输入虚拟钱包类型',
    shezhianquanma: '设置安全码',
    yuananquanma: '原安全码',
    xinanquanma: '新安全码',
    planquanma: '请输入原安全码',
    plxinanquanma: '請输入新安全码',
    queren: '确认',
    quxiao: '取消',
    anquanmaTps: ' 请设置安全码，请勿与银行卡密码相同',
    anquanmaTpsTow: ' 尊敬的客户，此安全码是您转出时的密码，为了安全起见，请尽量不要与登录密码相同',
    xiugaidenglu: '修改密码',
    jiumima: '旧密码',
    xinmima: '新密码',
    querenmima: '确认密码',
    pljiumima: '请输入旧密码',
    plxinmima: '请输入新密码',
    plquerenmima: '请再次输入密码',
    wancheng: '完成',
    kaishitouzi: '开始投资',
    kaiTps: '当前线路无法使用时，可尝试切换其他线路',
    zuiyou: '最优',
    dangaqianxianlu: '当前线路',
    dangqianbanben: '当前版本',
    banquansuoyou: '版权所有',
    yinglizhendong: '盈利震动',
    gensuixitong: '跟随系统',
    zhuanchujine: '转出金额',
    plzhuanchu: '请输入转出金额',
    zhuanchuTps: '温馨提示：如有疑问请联系平台客服。',
    xuanzeyh: '请选择提款银行卡',
    xuanzehb: '请选择提款虚拟钱包',
    tijiaoshenqing: '提交申请',
    shuruanquanma: '请输入安全码',
    xianshangcunru: '线上存入',
    saomacunru: 'USDT',
    wangyincunru: "银行存入",
    shijian: '时间',
    upshijian: '开始时间',
    downshijian: '结束时间',
    wuTps: '暂无相关数据~',
    jiazaicx: '重新加载',
    di: '第',
    lun: '轮',
    weiyingli: '未盈利',
    yishouli: '已受理',
    zhuangtai: '状态',
    chongzhi: '重置',
    riqi: '日期',
    CapActive: {
        chongzhi: '充值',
        tixian: '提现',
        goumai: '购买',
        yingli: '盈利',
        zengsong: '赠送',
        weitongguo: '未通过',
        yitongguo: '已通过',
        yijujue: '已拒绝',
    },
    zhanneixiaoxi: "站内消息",
    pingtaigonggao: '平台公告',
    fuzhilanjie: "复制链接",
    denglu: '登录',
    zhuce: '注册',
    jizhumima: '记住密码',
    wangjimima: '忘记密码',
    youkedenglu: '游客登陆',
    zhucezhanghao: "注册账户",
    plusername: '请输入用户名',
    plpassword: '请输入密码',
    wanchengzhuce: '完成注册',
    yiyouzhanghao: '已有账户',
    qingdenglu: '请登录',
    remenhuati: '热门话题',
    news1: '原创',
    news2: '推荐',
    news3: '财富',
    renliulan: '人浏览',
    hezhi: '和值',
    lunshu: '轮数',

    大: '大',
    小: '小',
    大单: '大单',
    大双: '大双',
    小单: '小单',
    小双: '小双',
    单: '单',
    双: '双',
    极小: '极小',
    极大: '极大',
    qi: '期',
    juli: '距离',
    lunjiezhi: '轮截至',
    yifengpan: '已封盘',
    yiguanbi: '已关闭',
    yonghu: '用户',
    danlunjiaoyi: '单轮交易',
    caozuo: '操作',
    pljoin: '加入聊天室成功',
    pltingshou: '产品已停售',
    gengou: "跟购",
    quedinggengou: "确定跟购吗",
    wanjia: "玩家",
    leibie: "类别",
    jine: "金额",
    gouru: '投注',
    zanweigouru: '暂未购入',
    qigou: '起购',
    gourushuliang: '购入数量',
    changgui: '常规',
    shuzi: '数字',
    yinglihezhi: '盈利和值',
    shouyijieshao: '收益介绍',
    lijicunru: '立即存入',
    kaihaojieguo: '开号结果',
    kaihaoqushi: '开号趋势',
    chanpingshuoming: '产品说明',
    qingchuhuancun: '清除缓存',
    youke: '游客',
    gongxi: '恭喜 ',
    zai: ' 在 ',
    yingli: ' 盈利 ',
    xiadanchenggong: '下单成功',
    jiazaizhong: '加载中...',
    guanbitg: '已关闭，停止购入',
    xiangoudl: '只能跟购当前轮',
    liaotiantip: '系统已禁言，请选择购入',
    tishi: '提示',
    zhidao: '知道了',
    zhuanchusb: '转出失败',
    zhuanchusbs: '余额不足，转出失败',
    buyxq: '购入详情',
    orderNo: '账单编号',
    plan_name: '厅房信息',
    buyxx: '购入信息',
    haoxx: '选号详情',
    buysj: '购入时间',
    yilou: '遗漏',
    fzjqb: '已复制到粘贴板',
    chanpin1: '开售时间：每3.5分钟为一轮，当日10:00AM开盘，次日02:00AM停盘。',
    chanpin2: '每轮有3个0-9的数字，3个数字相加为最终结果，可以投资结果做多做空平单平双极阳极阴以及数字。',
    Tips: {
        wjmm: '忘记密码请联系客服修改!',
        dlcg: '登录成功',
        tjcg: '提交成功',
        zanwu: '暂无数据',
        buyizhi: '两次密码不一致',
        zcbz: '您的资产不足',
        pltxje: '请输入提现金额',
        zdtx: '最低提现100',
        youke: '尊敬的游客您好：请您注册正式会员后操作。',
        szcg: '设置成功',
    },
    共1注: '共1注',
    wallet: '钱包',
    guanfangtuijian: '官方推荐',
    yonghudongtai: '用户动态',
    lijijinru: '立即进入',
    lijizhuli: '立即助力',
    zztzz: '正在投注中',
    "立即登录": "立即登录",
    "混合": "混合",
    "特码": "特码",
    "玩法": "玩法",
    "游戏规则": "游戏规则",
    "公告": ' 尊敬的客户您好，平台夜间停止办理业务，请于每日12:00---23:30联系客服办理业务，感谢您对的支持，祝您生活愉快！ ',
    "短期理财": "短期理财",
    "长期理财": '长期理财',
    "初级VIP": "初级VIP",
    "中级VIP": "中级VIP",
    "高级VIP": "高级VIP",
    "至尊VIP": "至尊VIP",
    "大": "大",
    "小": "小",
    "单": "单",
    "双": "双",
    "大单": "大单",
    "大双": "大双",
    "小单": "小单",
    "小双": "小双",
    "极小": "极小",
    "极大": "极大",
    "已选择": "已选择",
    "注": "注",
    "账户充值": "账户充值",
    "充值金额": "充值金额",
    "最低充值金额": "最低充值金额",
    "到帐账号": "到帐账号",
    "支付方式": "支付方式",
    "充值汇率": "充值汇率",
    "请确认您的账号。最低充值金额为": "请确认您的账号。最低充值金额为",
    "请按照上面提供的支付方式选择进行支付": "请按照上面提供的支付方式选择进行支付",
    "支付时，请备注您支付的订单号提交审核。提交成功后，请等待到帐确认": "支付时，请备注您支付的订单号提交审核。提交成功后，请等待到帐确认",
    "资金变动": "资金变动",
    "审核中": "审核中",
    "已通过": "已通过",
    "已拒绝": "已拒绝",
    "客服中心": "客服中心",
    "在线客服": "在线客服",
    "如何充值？": "如何充值？",
    "联系客服，银行卡转账": "联系客服，银行卡转账",
    "忘记了密码怎么办？": "忘记了密码怎么办？",
    "请及时提供相应信息给客服，系统将重置一个新密码，为保障账户安全，建议用户登录后立即修改密码。": "请及时提供相应信息给客服，系统将重置一个新密码，为保障账户安全，建议用户登录后立即修改密码。 ",
    "如何开通网上支付？": "如何开通网上支付？",
    "开通银行卡网上支付功能，包括网上开通和现场开通两种方式：": "开通银行卡网上支付功能，包括网上开通和现场开通两种方式：",
    "（1）网上开通：登录各大银行的官方网站在线开通。": "（1）网上开通：登录各大银行的官方网站在线开通。",
    "（2）现场开通：前往各大银行的营业网点现场开通。": "（2）现场开通：前往各大银行的营业网点现场开通。",
    "提款次数有没有限制？": "提款次数有没有限制？",
    "本公司不限制提款次数。": "本公司不限制提款次数。",
    "提款需要手续费吗？": "提款需要手续费吗？",
    "达到提款条件才能提款，提款不限次数，手续费根据本站设定。提款时产生的银行手续费将全部由本站为您承担。": "达到提款条件才能提款，提款不限次数，手续费根据本站设定。提款时产生的银行手续费将全部由本站为您承担。",
    "邀请码": "邀请码",
    "欢迎光临": "欢迎光临",
    "马上注册": "马上注册",
    "请输入邀请码": "请输入邀请码",
    "请输入验证码": "请输入验证码",
    "我已年满18周岁且同意接受": "我已年满18周岁且同意接受",
    "立即登陆": "立即登陆",
    "恭喜中奖了": "恭喜中奖了",
    "太棒了！恭喜获得！": "太棒了！恭喜获得！",
    "和值竞猜游戏，依照福利彩兼发行中心统一发行的竞猜游戏 彩票开奖为依据所规划的线上彩票游戏，完全公开、公平、公正、开奖透明，无法作弊": " 和值竞猜游戏，依照福利彩兼发行中心统一发行的竞猜游戏 彩票开奖为依据所规划的线上彩票游戏，完全公开、公平、公正、开奖透明，无法作弊",
    "竞猜游戏实行自愿购买，凡购买者均被视为同意并遵守本规则": "竞猜游戏实行自愿购买，凡购买者均被视为同意并遵守本规则",
    "游戏介绍": "游戏介绍",
    "游戏玩法": "游戏玩法",
    "将叁个数值进行相加后所得结果，即为和值": "将叁个数值进行相加后所得结果，即为和值",
    "温馨提示": "温馨提示",
    "平台禁止在同一期反向购买两注相同金额（即大、小或者单、双），此操作属于违法行为，一经发现，将冻结其账号": " 平台禁止在同一期反向购买两注相同金额（即大、小或者单、双），此操作属于违法行为，一经发现，将冻结其账号 ",
    "平台每日提现需完成账户余额一倍打码量，没有完成当日打码量的，次日零点清零重新计算": " 平台每日提现需完成账户余额一倍打码量，没有完成当日打码量的，次日零点清零重新计算 ",
    "保存": "保存",
    "更改头像": "更改头像",
    "身份证号码": "身份证号码",
    "请输入身份证号码": "请输入身份证号码",
    "从相册选择": "从相册选择",
    "拍照": "拍照",
    "系统默认图片": "系统默认图片",
    "冻结金额": "冻结金额",
    "提现资金不足": "提现资金不足",
    "您的资产不足请存入": "您的资产不足请存入",
    "我的团队": "我的团队",
    "用户名": "用户名",
    "余额": "余额",
    "类型": "类型",
    "做多": "做多",
    "做空": "做空",
    "平单": "平单",
    "平双": "平双",
    "姓名": "姓名",
    "手机号": "手机号",
    "流水必須達到": "流水必须达到"
}
export default zh;