const en = {
    jiantizhongwen: 'English',
    xianlu: 'Line ',
    dianwei: 'Point',
    wodezichan: 'My assets',
    touzikuaixun: 'Investment News',
    jiaoyiyonghu: 'User',
    jiaoyipinglei: 'Category',
    danlunhuoli: 'Profit',
    jiaoyiriqi: 'Date',
    dengluhouchakan: 'View after login',
    shouye: 'Home',
    jiaoyidating: 'hall',
    yucun: 'Prestore',
    zoushi: 'Trend',
    kefu: 'Customer',
    wode: 'Mine',
    zuiditouzi: 'Minimum investment',
    zuidijinru: 'Minimum entry',
    jichulicai: 'Basic Financing',
    zishenlicai: 'Senior Financing',
    dashilicai: 'Master Financing',
    zhuanjialicai: 'Expert Financing',
    基础理财: 'Basic Financing',
    资深理财: 'Senior Financing',
    大师理财: 'Master Financing',
    专家理财: 'Expert Financing',
    huiyuanquanyi: 'Member benefits',
    zuoriyingli: 'Profit yesterday',
    zongzichan: 'Total assets',
    chucunjin: 'Money',
    jinriyingli: 'Profit today',
    zhuanchu: 'Withdraw',
    cunru: 'Deposit',
    zhanghuxinxni: 'Account information',
    lishizhangdan: 'Historical bills',
    zijinminxi: 'Funding Details',
    renwuzhongxin: 'Mission center',
    xiaoxigonggao: 'News announcement',
    tuijianfenxiang: 'Recommended to share',
    huodongzhongxin: 'Activity Center',
    jibenziliao: 'Basic information',
    dengluzhanghao: 'Login account',
    nicheng: 'Nick name',
    shezhi: 'Settings',
    genggaixianlu: 'Change line',
    guanyuwomen: 'About us',
    xitongshezhi: 'System settings',
    yuyanshezhi: 'Language settings',
    tuichudenglu: 'Logout',
    bangdingyinhangka: 'Bind bank card',
    bangdingxuniqianbao: 'Bind virtual wallet',
    anquanma: 'Security code',
    xiugailenglumima: 'Modify login password',
    yinhangkaguanli: 'Bank card management',
    tianjiayinhangka: 'Add a bank card',
    xuniqianbaoguanli: 'Virtual wallet management',
    tianjiaxuniqianbao: 'Add virtual wallet',
    chiakren: 'Cardholder',
    yinhangkakahao: 'Bank card number',
    yinhangmingcheng: 'Bank name',
    yhkTps: 'Please bind the cardholder own bank card',
    nixiqianbaodizhi: 'Wallet address',
    xuniqianbaoleixing: 'Wallet Type',
    xnqbTps: ' Reminder: Please fill in the USDT address type, such as: trc20, erc20, omni. ',
    plchikaren: 'Please enter cardholder',
    plkahao: 'Please enter cardholder card number',
    plyinhangmingcheng: 'Please enter bank name',
    plqbdz: 'Please enter the virtual wallet address',
    plqblx: 'Please enter virtual wallet type',
    shezhianquanma: 'Set security code',
    yuananquanma: 'Original security code',
    xinanquanma: 'New security code',
    planquanma: 'Please enter the original security code',
    plxinanquanma: 'Please enter a new security code',
    queren: 'Confirm',
    quxiao: 'Cancel',
    anquanmaTps: ' Please set a security code, not the same as the bank card password',
    anquanmaTpsTow: ' Dear customer, this security code is the password when you transfer out. For security reasons, please try not to be the same as the login password',
    xiugaidenglu: 'Modify login password',
    jiumima: 'Old Password',
    xinmima: 'New password',
    querenmima: 'Confirm Password',
    pljiumima: 'Please enter old password',
    plxinmima: 'Please enter a new password',
    plquerenmima: 'Please enter the password again',
    wancheng: 'Complete',
    kaishitouzi: 'Start investing',
    kaiTps: 'When the current line is unavailable, try switching to other lines',
    zuiyou: 'Optimal',
    dangaqianxianlu: 'Current line',
    dangqianbanben: 'Current version',
    banquansuoyou: 'Copyright',
    yinglizhendong: 'Earnings shock',
    gensuixitong: 'Default',
    zhuanchujine: 'Amount',
    plzhuanchu: 'Please enter the transfer amount',
    zhuanchuTps: 'Tips: If you have any questions, please contact the platform customer service.',
    xuanzeyh: 'Please select a bank card for withdrawal',
    xuanzehb: 'Please select a virtual wallet for withdrawal',
    tijiaoshenqing: 'Submit application',
    shuruanquanma: 'Please enter security code',
    xianshangcunru: 'Online deposit',
    saomacunru: 'USDT',
    wangyincunru: "Banking deposit",
    shijian: 'Time',
    upshijian: 'Starting time',
    downshijian: 'End Time',
    wuTps: 'No data ~',
    jiazaicx: 'Reload',
    di: 'Period ',
    lun: ' ',
    weiyingli: 'Unprofitable',
    yishouli: 'Accepted',
    zhuangtai: 'Status',
    chongzhi: 'Reset',
    riqi: 'Date',
    CapActive: {
        chongzhi: 'Recharge',
        tixian: 'Withdraw',
        goumai: 'Buy',
        yingli: 'Profit',
        zengsong: 'Give away',
        weitongguo: 'Did not pass',
        yitongguo: 'Passed',
        yijujue: 'Rejected',
    },
    zhanneixiaoxi: "Information",
    pingtaigonggao: 'Announcement',
    fuzhilanjie: "copy Link",
    denglu: 'Login',
    zhuce: 'Register',
    jizhumima: 'Remember',
    wangjimima: 'Forgot password',
    youkedenglu: 'Visitor login',
    zhucezhanghao: "Register account",
    plusername: 'Please enter user name',
    plpassword: 'Please enter password',
    wanchengzhuce: 'Complete',
    yiyouzhanghao: 'Have an account',
    qingdenglu: 'Please sign in',
    remenhuati: 'Hot topic',
    news1: 'Original',
    news2: 'Recommend',
    news3: 'Asset',
    renliulan: ' browse',
    hezhi: 'And value',
    lunshu: 'Period',

    // 做多: 'Many',
    // 做空: 'Short',
    // 多单: 'Many Single',
    // 多双: 'Many Double',
    // 空单: 'Short Single',
    // 空双: 'Short Double',
    // 平单: 'Flat Single',
    // 平双: 'Flat Double',
    // 极阴: 'Cathode',
    // 极阳: 'Anode',
    大: 'Many',
    小: 'Short',
    大单: 'Many Single',
    大双: 'Many Double',
    小单: 'Short Single',
    小双: 'Short Double',
    单: 'Single',
    双: 'Double',
    极小: 'Cathode',
    极大: 'Anode',
    qi: '',
    juli: 'distance',
    lunjiezhi: 'end',
    yifengpan: 'Stop betting',
    yiguanbi: 'Closed',
    yonghu: 'User',
    danlunjiaoyi: 'Trade',
    caozuo: 'Operate',
    pljoin: 'Join the chat room successfully',
    pltingshou: 'Product has been discontinued',
    gengou: "Follow",
    quedinggengou: "Are you sure to follow the purchase?",
    wanjia: "player",
    leibie: "category",
    jine: "amount",
    gouru: 'Buy',
    zanweigouru: 'Not yet purchased',
    qigou: ' purchase',
    gourushuliang: 'Purchase quantity',
    changgui: 'Conventional',
    shuzi: 'Number',
    yinglihezhi: 'Profit and value',
    shouyijieshao: 'Income',
    lijicunru: 'Deposit',
    kaihaojieguo: 'Record',
    kaihaoqushi: 'Trend',
    chanpingshuoming: 'Description',
    qingchuhuancun: 'clear cache',
    youke: 'Visitor',
    gongxi: 'Congratulations to ',
    zai: ' in the ',
    yingli: ' profit ',
    xiadanchenggong: 'successfully ordered',
    jiazaizhong: 'Loading...',
    guanbitg: 'closed, stop buying',
    xiangoudl: 'Only follow the current round',
    liaotiantip: 'The system has been banned, please choose to buy',
    tishi: 'Tip',
    zhidao: 'OK',
    zhuanchusb: 'Failed to transfer out',
    zhuanchusbs: 'Insufficient balance, transfer out failed',
    buyxq: 'Purchase details',
    orderNo: 'Bill No.',
    plan_name: 'Hall information',
    buyxx: 'Buy information',
    haoxx: 'Number selection details',
    buysj: 'Buy time',
    yilou: 'missing',
    fzjqb: 'Copied to clipboard',
    chanpin1: 'Sale time: every 3.5 minutes is a round, opening at 10:00AM on the same day and closing at 02:00AM the next day. ',
    chanpin2: 'There are 3 numbers from 0 to 9 in each round. The sum of the 3 numbers is the final result. You can invest in the result: Many, Short, Flat Single, Flat Double, Many Single, Many Double, Short Single, Short Double, Cathode, Anode, and number. ',
    Tips: {
        wjmm: 'Forgot your password, please contact customer service to modify it!',
        dlcg: 'Login successful',
        tjcg: 'Submitted successfully',
        zanwu: 'No data',
        buyizhi: 'The two passwords are inconsistent',
        zcbz: 'Your assets are insufficient, please deposit',
        pltxje: 'Please enter the withdrawal amount',
        zdtx: 'Minimum withdrawal 100',
        youke: 'Dear tourists: Please register as a full member and operate. ',
        szcg: 'set successfully',
    },
    共1注: 'Total 1 bet',
    wallet: 'Wallet',
    guanfangtuijian: 'Official Recommendation',
    yonghudongtai: 'User Dynamics',
    lijijinru: 'Enter Now',
    lijizhuli: 'Upport Now',
    zztzz: 'betting in progress',
    "立即登录": "Login",
    "混合": "Mix",
    "特码": "Special code",
    "玩法": "How to play",
    "游戏规则": "Game Rules",
    "公告": 'Dear Customer, hello. The platform stops processing business at night. Please contact customer service for assistance between 12:00 and 23:30 daily. Thank you for your support, and we wish you a pleasant life! ',
    "短期理财": 'Short Investment',
    "长期理财": 'Long Investment',
    "初级VIP": "Junior VIP",
    "中级VIP": "Intermediate VIP",
    "高级VIP": "Senior VIP",
    "至尊VIP": "Supreme VIP",
    "大": "Big",
    "小": "Small",
    "单": "Single",
    "双": "Double",
    "大单": "Big Single",
    "大双": "Big Double",
    "小单": "Small Single",
    "小双": "Small Double",
    "极小": "Extremely Small",
    "极大": "Extremely Big",
    "已选择": "Selected",
    "注": "Note",
    "账户充值": "Account Recharge",
    "充值金额": "Recharge Amount",
    "最低充值金额": "Minimum Recharge Amount",
    "到帐账号": "Account Number",
    "支付方式": "Payment Method",
    "充值汇率": "Recharge Rate",
    "请确认您的账号。最低充值金额为": "Please confirm your account. The minimum recharge amount is:",
    "请按照上面提供的支付方式选择进行支付": "Please choose the payment method provided above to make a payment.",
    "支付时，请备注您支付的订单号提交审核。提交成功后，请等待到帐确认": "When making a payment, please note your order number for review. After submission, please wait for account confirmation.",
    "资金变动": "Funds Change",

    "审核中": "In Review",
    "已通过": "Approved",
    "已拒绝": "Rejected",
    "客服中心": "Customer Service Center",
    "在线客服": "Online Customer Service",
    "如何充值？": "How to Recharge?",
    "联系客服，银行卡转账": "Contact Customer Service for Bank Transfer",
    "忘记了密码怎么办？": "What to Do If I Forget My Password?",
    "请及时提供相应信息给客服，系统将重置一个新密码，为保障账户安全，建议用户登录后立即修改密码。": "Please provide the relevant information to customer service promptly. The system will reset a new password. For account security, users are advised to change their password immediately after logging in.",
    "如何开通网上支付？": "How to Activate Online Payment?",
    "开通银行卡网上支付功能，包括网上开通和现场开通两种方式：": "Activate the online payment function for bank cards, which can be done online or on-site:",
    "（1）网上开通：登录各大银行的官方网站在线开通。": "(1) Online Activation: Log in to the official websites of major banks to activate online.",
    "（2）现场开通：前往各大银行的营业网点现场开通。": "(2) On-site Activation: Visit the branches of major banks for on-site activation.",
    "提款次数有没有限制？": "Is There a Limit on Withdrawal Times?",
    "本公司不限制提款次数。": "Our company does not limit the number of withdrawals.",
    "提款需要手续费吗？": "Are There Fees for Withdrawals?",
    "达到提款条件才能提款，提款不限次数，手续费根据本站设定。提款时产生的银行手续费将全部由本站为您承担。": "Withdrawals can only be made when conditions are met, with no limit on the number of withdrawals. Fees are set by our site, and any bank fees incurred will be fully covered by us.",
    "邀请码": "Invitation Code",
    "欢迎光临": "Welcome",
    "马上注册": "Register Now",
    "请输入邀请码": "Please Enter Invitation Code",
    "请输入验证码": "Please Enter Verification Code",
    "我已年满18周岁且同意接受": "I am over 18 years old and agree to accept",
    "立即登陆": "Log In Now",
    "恭喜中奖了": "Congratulations!",
    "太棒了！恭喜获得！": "Awesome!",
    "和值竞猜游戏，依照福利彩兼发行中心统一发行的竞猜游戏 彩票开奖为依据所规划的线上彩票游戏，完全公开、公平、公正、开奖透明，无法作弊": "The sum guessing game is an online lottery game based on the lottery results issued by the Welfare Lottery and Distribution Center, completely open, fair, just, and transparent, with no possibility of cheating.",
    "竞猜游戏实行自愿购买，凡购买者均被视为同意并遵守本规则": "The guessing game is based on voluntary purchase, and all purchasers are deemed to agree and comply with this rule.",
    "游戏介绍": "Game Introduction",
    "游戏玩法": "Game Rules",
    "将叁个数值进行相加后所得结果，即为和值": "The result obtained by adding three values together is the sum.",
    "温馨提示": "Friendly Reminder",
    "平台禁止在同一期反向购买两注相同金额（即大、小或者单、双），此操作属于违法行为，一经发现，将冻结其账号": "The platform prohibits purchasing two identical amounts (i.e., big, small, or odd, even) in the same period, as this is considered illegal behavior. If discovered, the account will be frozen.",
    "平台每日提现需完成账户余额一倍打码量，没有完成当日打码量的，次日零点清零重新计算": "The platform requires daily withdrawals to complete a wagering amount equal to the account balance. If the wagering amount for the day is not completed, it will reset to zero at midnight for recalculation.",
    "保存": "Save",
    "更改头像": "Change Avatar",
    "身份证号码": "ID Number",
    "请输入身份证号码": "Please enter your ID number.",
    "更改头像": "Change Avatar",
    "身份证号码": "ID Number",
    "请输入身份证号码": "Please Enter Your ID Number",
    "从相册选择": "Select from Album",
    "拍照": "Take Photo",
    "系统默认图片": "Default System Image",
    "冻结金额": "Frozen Amount",
    "提现资金不足": "Insufficient Withdrawal Funds",
    "您的资产不足请存入": "Your assets are insufficient, please deposit.",
    "我的团队": "My Team",
    "用户名": "Username",
    "余额": "Balance",
    "类型": "Type",
    "做多": "Long",
    "做空": "Short",
    "平单": "Close Single",
    "平双": "Close Double",
    "姓名": "Name",
    "手机号": "Phone number",
    "流水必須達到": "The transaction amount must be greater than or equal to",
}
export default en;