<template>
  <div class="wallet">
    <div
      class="header"
      style="position: relative; height: auto; background: #1c1c34"
    >
      <div class="wallet_lump">
        <div class="balance">{{ $t("zongzichan") }}</div>
        <div class="number">
          <span v-if="eyeis == 2" id="yue">{{ money }}</span>
          <span v-else id="yue">****</span>
        </div>

        <img
          v-if="eyeis == 1"
          class="eye"
          src="../assets/image/closeeye.png"
          @click="toggleNumber(2)"
        />

        <img
          v-else
          class="eye"
          src="../assets/image/openeye.png"
          @click="toggleNumber(1)"
        />
      </div>
    </div>

    <div
      style="
        position: relative;
        overflow: auto;
        margin-top: -0.6rem;
        z-index: 9999;
      "
      class="am-list-view-scrollview"
    >
      <div class="my_money_recharge_withdraw">
        <div @click="changeRecharge">
          <img
            src="../assets/image/icon_withdraw_red.png"
            style="height: 37px"
          />
          <span>{{ $t("cunru") }}</span>
        </div>
        <div @click="changeWithdraw">
          <img
            src="../assets/image/icon_recharge_red.png"
            style="height: 37px"
          />
          <span>{{ $t("zhuanchu") }}</span>
        </div>
        <div @click="changebane(0)">
          <img src="../assets/image/icon_bankcard2.png" style="height: 37px" />
          <span>{{ $t("yinhangkaguanli") }}</span>
        </div>
        <div @click="changebane(1)">
          <img src="../assets/image/icon_usdt.png" style="height: 37px" />
          <span>{{ $t("xuniqianbaoguanli") }}</span>
        </div>
      </div>

      <div class="my_item_div">
        <div style="border-left: medium none" @click="changeCapitalDetail">
          <span class="ele">
            <span
              ><img src="../assets/image/icon_zjjl.png" />{{
                $t("zijinminxi")
              }}</span
            >
            <img src="../assets/image/icon_right_arrow.png" />
          </span>
        </div>
        <div style="border-left: medium none" @click="changeSetSecurityCode">
          <span class="ele">
            <span
              ><img src="../assets/image/icon_zjmm.png" />{{
                $t("anquanma")
              }}</span
            >
            <img src="../assets/image/icon_right_arrow.png" />
          </span>
        </div>
      </div>
    </div>

    <!-- <div class="settingwarp">
			<div class="g-content flex-column">
				<div class="mescroll">
					<div>
						<div class="scroll-wrapper">
							<div class="balance">
								<div class="money-box">
									<p class="num">{{ money }}</p>
									<p>{{ $t("zongzichan") }}</p>
								</div>
							</div>
						</div>
					</div>
					<div class="cont-box">
						<ul class="tx-box">
							<li class="tx" @click="changeWithdraw">
								<img src="@/assets/image/zhuanchu.png" class="icon" alt="" />
								<span class="text">{{ $t("zhuanchu") }}</span>
							</li>
							<li class="tx" @click="changeRecharge">
								<img src="@/assets/image/cunru.png" class="icon" alt="" />
								<span class="text">{{ $t("cunru") }}</span>
							</li>
						</ul>
						<ul class="list-box">
							<li class="list" @click="changebane(0)">
								<div class="list-text">{{ $t("yinhangkaguanli") }}</div>
								<img src="@/assets/image/you.png" class="arrow" alt="" />
							</li>
							<li class="list" @click="changebane(1)">
								<div class="list-text">{{ $t("xuniqianbaoguanli") }}</div>
								<img src="@/assets/image/you.png" class="arrow" alt="" />
							</li>
							<li class="list" @click="changeCapitalDetail">
								<div class="list-text">{{ $t("zijinminxi") }}</div>
								<img src="@/assets/image/you.png" class="arrow" alt="" />
							</li>
							<li class="list" @click="changeSetSecurityCode">
								<div class="list-text">{{ $t("anquanma") }}</div>
								<img src="@/assets/image/you.png" class="arrow" alt="" />
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div> -->
    <tab-bar></tab-bar>
  </div>
</template>

<script>
import TabBar from "@/components/tabbar/TabBar.vue";
import userApi from "@/api/user";
import storeAction from "@/store/typed-actions";
export default {
  name: "wallet",
  components: {
    TabBar,
  },
  data() {
    return {
      money: this.$t("jiazaizhong"),
      eyeis: 1,
      user: "",
    };
  },
  created() {
    // this.info();
    this.onRefresh();
  },
  methods: {
    onRefresh() {
      // this.$toast.loading({
      //   message: this.$t("jiazaizhong"),
      //   forbidClick: true,
      //   duration: 0,
      // });
      userApi.userInfo().then((data) => {
        console.log(data.msg);
        this.user = data.msg;
        this.money = data.msg.money;
        storeAction.setCurrentUserMoney(data.msg.money);
        storeAction.setCurrentUserGroup_id(data.msg.group_id);
        storeAction.setsessionStorage("money", data.msg.money);
        storeAction.setsessionStorage("group_id", data.msg.group_id);
        storeAction.setsessionStorage("mobile", data.msg.mobile);
        storeAction.setCurrentUserMobile(data.msg.mobile);
        // storeAction.setsessionStorage("idcard", data.msg.idcard);
        // storeAction.setCurrentUserusercrud(data.msg.idcard);
        this.isLoading = false;
        this.$toast.clear();
      });
    },

    changeWithdraw() {
      this.$router.push({
        path: "/Withdraw",
        query: {
          group_id: this.user.group_id,
        },
      });
    },

    changeRecharge() {
      this.$router.push({
        path: "/Recharge",
        query: {
          group_id: this.user.group_id,
          username: this.user.username,
        },
      });
    },

    toggleNumber(type) {
      this.eyeis = type;
    },
    info() {
      this.$toast.loading({
        message: this.$t("jiazaizhong"),
        forbidClick: true,
        duration: 0,
      });
      userApi
        .recharge()
        .then((data) => {
          console.log(data.data);
          this.money = data.data.money;
          this.usdt = data.data.usdt;
          this.yinhang = data.data.bankname;
          this.kahao = data.data.banknum;
          this.xingming = data.data.bankxm;
          this.$toast.clear();
          this.PopAlert = true;
        })
        .catch((err) => {
          this.$toast.clear();
          this.$toast.fail(err.msg);
        });
    },
    changebane(type) {
      this.$router.push({
        path: "/bankCardManage",
        query: {
          type: type,
        },
      });
    },
    changeCapitalDetail() {
      this.$router.push({
        path: "/CapitalDetail",
      });
    },
    changeSetSecurityCode() {
      userApi.safetyInfo().then((data) => {
        console.log(data.data.isTrue);
        let isTrue = data.data.isTrue;
        this.$router.push({
          path: "/setSecurityCode",
          query: {
            isTrue: isTrue,
          },
        });
      });
    },
  },
};
</script>
<style lang="less" scoped>
.wallet {
  background-color: #f3f3f3;
}

.header {
  background: none !important;
}

// .header {
//     width: 7.5rem !important;
//     left: 0;
//     right: 0;
//     margin: auto !important;
// }
.header {
  position: fixed;
  overflow: hidden;
  top: 0;
  width: 100%;
  max-width: 750px;
  margin: 0 auto;
  height: 48px;
  line-height: 48px;
  font-size: 14px;
  color: #fff;
  text-align: center;
  background: linear-gradient(180deg, #1d2035 0%, #1d2035 100%);
  z-index: 100;
}

.header .wallet_lump {
  position: relative;
  width: 100%;
  height: 150px;
  margin: 0 auto;
  line-height: 0.98rem;
  font-size: 0.36rem;
  color: #fff;
  text-align: center;
  background: linear-gradient(180deg, #2e3564 0%, #20243e 100%);
  border-radius: 0 0 15% 15%;
}

.header .wallet_lump .balance {
  position: absolute;
  margin: auto;
  top: 10px;
  left: 25px;
  font-size: 16px;
}

.header .wallet_lump .number {
  position: absolute;
  margin: auto;
  top: 70px;
  left: 25px;
  font-size: 26px;
}

.header .wallet_lump .eye {
  position: absolute;
  margin: auto;
  top: 17px;
  right: 25px;
  width: auto;
  height: 25px;
}

.my_money_recharge_withdraw {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  box-shadow: 0px 0px 1px 1px rgb(0 0 0 / 20%);
  background: #ffffff;
  padding: 20px 0;
  width: 95%;
  margin: 0 2.5%;
  border-radius: 0.15rem;
  span {
    margin-top: 10px;
    font-size: 14px;
    line-height: 20px;
    color: #909090;
  }
}

.my_money_recharge_withdraw > div img {
  height: 37px;
  width: 37px;
  margin: 0 auto;
  display: block;
}

.my_money_recharge_withdraw > div:last-child {
  border-right: none;
}

.my_item_div {
  width: 95%;
  margin: 15px 10px;
  border-radius: 0.15rem;
  background: #ffffff;
}

.my_item_div > div {
  width: 100% !important;
  border: none !important;
  height: auto;
  margin-bottom: 0;
}

.my_item_div > div:nth-child(1) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.my_item_div > div:nth-child(2) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.my_item_div > div:nth-child(3) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.my_item_div > div:nth-child(4) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.my_item_div .ele {
  position: relative;
  display: grid;
  grid-template-columns: auto auto;
  width: 315px;
  margin: 0 auto;
  text-align: left;
  line-height: 50px;
  font-size: 13px;
  border-bottom: 0.01rem solid #e5e5e5;
  color: #555555;
}

.my_item_div .ele img:first-child {
  width: 20px;
  margin-right: 10px;
}

.my_item_div .ele > img {
  position: absolute;
  top: 0.42rem;
  right: 0;
  width: 20px;
}
</style>
