<template>
  <div class="lt-list">
    <div
      v-for="(item, index) in list"
      class="item"
      :class="[{ bg1: index == 0 }, { bg2: index == 1 }]"
      @click="jump(item)"
    >
      <div class="item-text big">{{ $t(item.name) }}</div>
      <div class="item-text">{{ $t("lijijinru") }}</div>
    </div>
  </div>
</template>
<script>
import homeApi from "@/api/home";
export default {
  data() {
    return {
      list: [],
    };
  },
  computed: {},
  methods: {
    info() {
      this.$toast.loading({
        message: this.$t("jiazaizhong"),
        forbidClick: true,
        duration: 0,
      });
      homeApi
        .Lotteryinfo("", "")
        .then((data) => {
          this.list = data.data?.gameList;
          console.log(this.list, "this.list");
          this.$toast.clear();
        })
        .catch((err) => {
          this.$toast.clear();
          this.$toast.fail(err.msg);
        });
    },
    jump(item) {
      this.$router.push({
        path: "/lottery",
        query: {
          id: item.id,
          name: item.name,
        },
      });
    },
  },
  mounted() {},
  created() {
    this.info();
  },
};
</script>
<style lang="less" scoped>
.lt-lis ::-webkit-scrollbar {
  display: none;
}

.swiper-container {
  // height: 300px;
  width: 100%;

  .swiper-wrapper {
    .swiper-slide {
    }
  }
}

.level-box {
}

.level {
  height: 3rem;
  margin-bottom: 0.2rem;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url(../../assets/image/bg.png);
  text-align: left;
}

.level1 {
  height: 3rem;
  margin-bottom: 0.2rem;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url(../../assets/image/bg2.png);
  text-align: left;
}

.level2 {
  height: 3rem;
  margin-bottom: 0.2rem;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url(../../assets/image/bg3.png);
  text-align: left;
}

.level3 {
  height: 3rem;
  margin-bottom: 0.2rem;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url(../../assets/image/bg4.png);
  text-align: left;
}

.title {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.5rem 0.56rem 0;
  font-size: 0.45rem;
}

.level-name {
  width: 2.5rem;
}

.people-box {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;

  margin-left: 0.2rem;
  font-size: 0.45rem;

  .icon {
    margin-right: 0.06rem;
    width: 0.5rem;
    height: 0.5rem;
  }
}

.text-box {
  padding: 0.3rem 0.56rem 0;
  font-size: 0.3rem;
  text-align: left;
}

.text {
  min-width: 2.1rem;
}

.price {
  padding-left: 0.1rem;
}

.text:last-child {
  margin-top: 0.2rem;
}

.item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 0.3rem;
  padding: 0.8rem 0.4rem;
  height: 3.6rem;
  border-radius: 0.2rem;

  .item-text {
    text-align: left;
    color: #fff;
    font-size: 0.35rem;
  }

  .big {
    font-size: 0.6rem;
  }
}

.bg1 {
  background-image: url("../../assets/image/a1.png");
  background-size: cover;
}

.bg2 {
  margin-top: 0.3rem;
  background-image: url("../../assets/image/a2.png");
  background-size: cover;
}
</style>
