<template>
  <div class="main" style="background: #fafafa">
    <!-- <div class="content">
			<iframe src="https://iarxr.top/addons/kefu" frameborder="0" width="100%" height="100%"></iframe>
		</div> -->
    <div class="header">
      <a class="back" href="?a=user" title="返回">
        <div @click="changeRouteHome" class="back-btn">
          <van-icon name="arrow-left" color="#fff" size="20px" />
        </div>
      </a>
      <a class="home" href="?a=init" title="首页"
        ><i class="iconfont" style="color: #ffffff; font-size: 22px"
          ><img
            style="width: auto; height: 26px"
            src="../assets/image/icon_bottom_home.png" /></i
      ></a>
      <h4>{{ $t("客服中心") }}</h4>
    </div>

    <div class="service-bg am-list-view-scrollview">
      <div
        style="position: absolute; min-width: 100%"
        class="am-list-view-scrollview-content am-list"
      >
        <div class="am-pull-to-refresh-content-wrapper">
          <div class="service_title" @click="openkf()">
            <!-- <a href="https://www.svctlives.com/kefu/66de207915715&amp;uid=1682813&amp;name=li1991">
							    <img src="https://www.hpdrmcsn.cc/statics/images/index/ico_kf.png?v=21" style="transform: scale(1);">
								<span style="color:white">在线客服</span>
							</a> -->

            <img
              src="../assets/image/ico_kf.png?v=21"
              style="transform: scale(1)"
            />
            <span style="color: white">{{ $t("在线客服") }}</span>
          </div>

          <!--div class="service_title">
							<a href="https://wa.me/88888888">
							    <img src="statics/images/index/ico_line.png?v=21" style="transform: scale(1);"/><span style="color:white">联系我们                    </a>
						</div>
						<div class="service_title">
							<a href="https://t.me/8888888888">
							    <img src="statics/images/index/ico_telegram.png?v=21" style="transform: scale(1);"/><span style="color:white">联系我们                    </a>
						</div-->

          <div
            class="am-pull-to-refresh-content am-pull-to-refresh-down am-pull-to-refresh-transition"
          >
            <div
              class="service_lump"
              style="
                background: white;
                margin: 0 20px 200px;
                border-radius: 5px;
                padding: 8px 0;
              "
            >
              <span class="ele">
                <div class="title">1、{{ $t("如何充值？") }}</div>
                <div class="content">{{ $t("联系客服，银行卡转账") }}</div>
              </span>

              <span class="ele">
                <div class="title">2、{{ $t("忘记了密码怎么办？") }}</div>
                <div class="content">
                  {{
                    $t(
                      "请及时提供相应信息给客服，系统将重置一个新密码，为保障账户安全，建议用户登录后立即修改密码。"
                    )
                  }}
                </div>
              </span>

              <span class="ele">
                <div class="title">3、{{ $t("如何开通网上支付？") }}</div>
                <div class="content">
                  {{
                    $t(
                      "开通银行卡网上支付功能，包括网上开通和现场开通两种方式："
                    )
                  }}<br />{{
                    $t("（1）网上开通：登录各大银行的官方网站在线开通。")
                  }}<br />{{
                    $t("（2）现场开通：前往各大银行的营业网点现场开通。")
                  }}
                </div>
              </span>

              <span class="ele">
                <div class="title">4、{{ $t("提款次数有没有限制？") }}</div>
                <div class="content">{{ $t("本公司不限制提款次数。") }}</div>
              </span>

              <span class="ele">
                <div class="title">5、{{ $t("提款需要手续费吗？") }}</div>
                <div class="content">
                  {{
                    $t(
                      "达到提款条件才能提款，提款不限次数，手续费根据本站设定。提款时产生的银行手续费将全部由本站为您承担。"
                    )
                  }}
                </div>
              </span>

              <!--span class="ele">
		    						<div class="title">6、哪里可以查到公司相关信息？</div>
		    						<div class="content">
		    							公司官方网站地址：<a style="color:red" target="_blank" href="">xxxxx</a></div>
								</span-->
            </div>
          </div>
        </div>
      </div>
    </div>

    <tab-bar></tab-bar>
  </div>
</template>

<script>
import TabBar from "@/components/tabbar/TabBar.vue";
export default {
  name: "customer",
  components: {
    TabBar,
  },
  //https://wei28.timibbs.vip/addons/kefu
  data() {
    return {};
  },
  methods: {
    openkf() {
      this.$router.push("/iframe");
    },
  },
};
</script>

<style lang="less" scoped>
// .content {
// 	height: calc(100vh - 1.32rem);
// 	height: calc(100vh - (1.32rem + constant(safe-area-inset-bottom)));
// 	height: calc(100vh - (1.32rem + env(safe-area-inset-bottom)));
// }
.main {
  // width: 7.5rem;
  /* height: auto; */
  margin: 0 auto;
  min-height: 100%;
}
.header {
  position: fixed;
  overflow: hidden;
  top: 0;
  width: 100%;
  max-width: 750px;
  margin: 0 auto;
  height: 48px;
  line-height: 48px;
  font-size: 14px;
  color: #fff;
  text-align: center;
  background: linear-gradient(180deg, #1d2035 0%, #1d2035 100%);
  z-index: 100;
}

.header {
  // width: 7.5rem !important;
  left: 0;
  right: 0;
  margin: auto !important;
}

.header a.home {
  left: initial;
  right: 10px;
}

.header a {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 26px;
  height: 26px;
  line-height: 26px;
}

a {
  text-decoration: none;
  color: #666;
  cursor: pointer;
}

.header h4 {
  width: 7.5rem !important;
  left: 0;
  right: 0;
  margin: auto !important;
}

.header h4 {
  font-size: 14px;
}

.service-bg {
  position: relative;
  overflow: auto;
  height: calc(50px + 100vh);
  padding-top: 0.88rem;
}

.service_title {
  width: 175px;
  height: 40px;
  line-height: 40px;
  margin: 60px auto;
  margin-top: 60px;
  background: linear-gradient(180deg, #2e3564 0%, #2f3665 100%);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.service_title img {
  position: relative;
  // top: 2px;
  width: 28px;
  height: 28px;
  // margin-left: 28px;
  margin-right: 10px;
}

.service_lump .ele {
  display: block;
  // width: 6.6rem;
  padding: 10px;
  height: auto;
  margin: 10px auto;
  background: white;
  border-radius: 0.1rem;
  text-align: left;
}
.service_lump .ele .title {
  font-size: 14px;
  /* font-weight: 700; */
  color: #ff8089;
  margin-bottom: 10px;
}
.service_lump .ele .content {
  font-size: 11px;
  font-weight: 400;
  color: #666666;
  margin: 0;
  line-height: 20px;
}
</style>
