<template>
  <div class="main">
    <div class="header">
      <a class="back" href="?a=user" title="返回">
        <div @click="changeRouteHome" class="back-btn">
          <van-icon name="arrow-left" color="#fff" size="20px" />
        </div>
      </a>
      <a class="home" href="?a=init" title="首页"
        ><i class="iconfont" style="color: #ffffff; font-size: 22px"
          ><img
            style="width: auto; height: 26px"
            src="../assets/image/icon_bottom_home.png" /></i
      ></a>
      <h4>{{ $t("账户充值") }}</h4>
    </div>

    <div class="scroll_box" style="margin-top: 52px">
      <div class="from_lump">
        <form
          action="?a=pay"
          method="post"
          id="send_form"
          onsubmit="return false;"
        >
          <input type="hidden" name="dosubmit" id="dosubmit" value="yes" />
          <input type="hidden" name="pay_type" id="pay_type" value="1" />
          <div class="form_div">
            <ul>
              <li>
                <span class="title">{{ $t("充值金额") }}</span>

                <van-cell-group class="login-body van-cell-group">
                  <van-field
                    :placeholder="`${$t('最低充值金额')}100`"
                    v-model="inputmoney"
                    clearable
                    style="margin-top: 10px; margin-bottom: 25px"
                    type="number"
                  >
                  </van-field>
                </van-cell-group>
              </li>
              <li>
                <span class="title">{{ $t("到帐账号") }}：{{ username }}</span>
              </li>
              <!-- <li>
										<input class="inputxt" type="text" id="comment" name="comment" value="" placeholder="您可以备注一段内容给代理或管理员" />
									</li> -->
              <li>
                <!--<span class="title">在线支付币种：
									<select name="type1">
									  <option value ="CNY">CNY</option>
									  <option value ="MYR">MYR</option>
									  <option value="USD">USD</option>
									  <option value="SGD">SGD</option>
									  <option value ="EUR">EUR</option>
									  <option value ="AUD">AUD</option>
									  <option value="THB">THB</option>
									  <option value="GBP">GBP</option>
									  <option value="HKD">HKD</option>
									  <option value="AED">AED</option>
									</select></span>-->
                <div class="title" style="padding-bottom: 20px">
                  <span class="title">{{ $t("支付方式") }}</span>
                  <select
                    name="type1"
                    id="type1"
                    class="select"
                    v-model="typeValue"
                  >
                    <option value="TWD" data-lv="1">TWD</option>
                    <option value="HKD" data-lv="1">HKD</option>
                    <option value="MYR" data-lv="1">MYR</option>
                    <option value="RMB" data-lv="1">RMB</option>
                    <option value="USDT" data-lv="1">USDT</option>
                    <option value="USD" data-lv="1">USD</option>
                    <option value="GBP" data-lv="1">GBP</option>
                    <option value="SGD" data-lv="1">SGD</option>
                    <option value="EUR" data-lv="1">EUR</option>
                    <option value="JPY" data-lv="1">JPY</option>
                    <option value="CHF" data-lv="1">CHF</option>
                    <option value="CAD" data-lv="1">CAD</option>
                    <option value="AUD" data-lv="1">AUD</option>
                    <option value="NZD" data-lv="1">NZD</option>
                    <option value="INR" data-lv="1">INR</option>
                  </select>
                  <!--<select name='type1'>
						 	    	<option value ="ylkj">银联快捷</option>
						 	    	<option value ="zfbsm">支付宝扫码</option>
						 	    </select>-->
                  <div class="rate" align="center" style="padding: 10px">
                    {{ $t("充值汇率") }}：<span id="hv">1:1</span>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <!-- <label style="display: none"
            ><input name="Fruit" type="radio" value="1" checked="" />
            <font size="5px">手动支付</font>
          </label>
          <label style="display: none"
            ><input name="Fruit" type="radio" value="2" />
            <font size="5px">在线支付</font>
          </label> -->

          <div
            style="margin: 0 auto; padding: 5%"
            class="submit"
            @click="submit"
          >
            <div class="btss">{{ $t("queren") }}</div>
          </div>
        </form>
      </div>

      <div class="tps">
        <h4></h4>
        <h4>{{ $t("tishi") }}：</h4>
        <p>1、{{ $t("请确认您的账号。最低充值金额为") }} ：$100</p>
        <p>2、{{ $t("请按照上面提供的支付方式选择进行支付") }}</p>
        <p>
          3、{{
            $t(
              "支付时，请备注您支付的订单号提交审核。提交成功后，请等待到帐确认"
            )
          }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import AlertPop from "@/components/alert/AlertPop.vue";
import userApi from "@/api/user";
export default {
  name: "recharge",
  components: {
    AlertPop,
  },
  data() {
    return {
      inputmoney: "",
      money: this.$t("jiazaizhong"),
      usdt: null,
      yinhang: null,
      kahao: null,
      xingming: null,
      alertType: null,
      TipsTxt: this.$t("Tips.youke"),
      PopAlert: false,
      username: this.$route.query.username || "",
      typeValue: "TWD",
    };
  },
  computed: {
    group_id() {
      return this.$store.state.group_id;
    },
  },
  methods: {
    showAletfalse() {
      this.PopAlert = false;
      this.$router.go(-1);
    },
    changego() {
      this.$router.go(-1);
    },
    submit() {
      if (this.inputmoney < 100) {
        this.$toast("最低充值金额100");
        return;
      }
      this.info();
    },
    info() {
      this.$toast.loading({
        message: this.$t("jiazaizhong"),
        forbidClick: true,
        duration: 0,
      });
      let params = {
        money: this.inputmoney,
        type: this.typeValue,
      };
      userApi
        .recharge(params)
        .then((res) => {
          console.log(res, "res");
          this.$toast.clear();
          if (res.code == 1) {
            this.$router.push("/capitalDetail");
          }
          this.username = data.data.username;
          // console.log(data.data);
          // this.money = data.data.money;
          // this.usdt = data.data.usdt;
          // this.yinhang = data.data.bankname;
          // this.kahao = data.data.banknum;
          // this.xingming = data.data.bankxm;
          // this.$toast.clear();
          // this.PopAlert = true;
        })
        .catch((err) => {
          this.$toast.clear();
          this.$toast.fail(err.msg);
        });
    },
  },
  mounted() {
    console.log(this.group_id);
  },
  created() {
    // this.info();
    if (this.group_id == 2) {
      this.alertType = 1;
    }
  },
};
</script>
<style lang="less" scoped>
.main {
  width: 100%;
  /*height: auto;*/
  margin: 0 auto;
  min-height: 100%;
}

.main {
  background: #fff !important;
  background-size: 100vw 100vh !important;
  width: 100%;
  height: 100%;
  max-width: 750px;
  margin: 0 auto;
}

.header {
  position: fixed;
  overflow: hidden;
  top: 0;
  width: 100%;
  max-width: 750px;
  margin: 0 auto;
  height: 48px;
  line-height: 48px;
  font-size: 14px;
  color: #fff;
  text-align: center;
  background: linear-gradient(180deg, #1d2035 0%, #1d2035 100%);
  z-index: 100;
}

.header {
  // width: 7.5rem !important;
  left: 0;
  right: 0;
  margin: auto !important;
}

.header a.home {
  left: initial;
  right: 10px;
}

.header a {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 26px;
  height: 26px;
  line-height: 26px;
}

a {
  text-decoration: none;
  color: #666;
  cursor: pointer;
}

.header h4 {
  width: 7.5rem !important;
  left: 0;
  right: 0;
  margin: auto !important;
}

.header h4 {
  font-size: 14px;
}

.zfb {
  background: #00aaef;
  width: 33.333%;
  display: inline-block;
  border: none;
  cursor: pointer;
  color: #fff;
  font-size: 16px;
  margin: 0 auto;
  text-align: center;
}

.wx {
  background: #00c70c;
  width: 33.333%;
  display: inline-block;
  border: none;
  cursor: pointer;
  color: #fff;
  font-size: 16px;
  margin: 0 auto;
  text-align: center;
}

.yhk {
  background: #f3be00;
  width: 33.333%;
  display: inline-block;
  border: none;
  cursor: pointer;
  color: #fff;
  font-size: 16px;
  margin: 0 auto;
  text-align: center;
}

.scroll_box {
  position: relative;
  max-width: 750px;
  margin: 49px auto 0;
  background: #efefef;
  overflow: hidden;
  overflow-y: auto;
}

.from_lump .form_div {
  background: white;
  padding: 0.2rem;
  padding-bottom: 0.01rem;
}

.form_div li .inputxt {
  display: block;
  width: 100%;
  height: 0.9rem;
  line-height: 0.9rem;
  background: #fff;
  font-size: 0.26rem;
  border: none;
  border-bottom: 0.01rem solid #f1f1f1;
  color: #444;
  box-sizing: border-box;
  padding-left: 10px;
}

input,
label,
img,
td,
th {
  vertical-align: middle;
}

.form_div li span.title {
  position: relative;
  margin-left: 0px;
  // color: #666;
  font-size: 13px;
  display: block;
  width: 100%;
  // height: 25px;
  // line-height: 25px;
  border-bottom: 0.01rem solid #f1f1f1;
  padding-bottom: 15px;
}

.form_div li .rate {
  display: inline-block;
  position: absolute;
  right: 0;
  top: 30px;
}

.form_div li .select {
  display: block;
  position: unset;
  margin-top: 0;
  width: 90px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border: 0.01rem solid #ccc;
  border-radius: 0.1rem;
  font-size: 13px;
}

.form_div ul li {
  margin-bottom: 15px;
  position: relative;
  text-align: left;
}

ul,
ol,
li {
  list-style: none;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
blockquote,
dl,
dt,
dd,
ul,
ol,
li,
pre,
form,
fieldset,
legend,
button,
input,
textarea,
th,
td {
  margin: 0;
  padding: 0;
  word-wrap: break-word;
}

.submit {
  overflow: hidden;
  position: relative;
  padding-top: 20px;
}

.submit .btss {
  width: 100%;
  height: 40px;
  line-height: 40px;
  display: inline-block;
  border: none;
  cursor: pointer;
  color: #fff;
  font-size: 16px;
  border-radius: 10px;
  margin: 0 auto;
  background: linear-gradient(180deg, #2e3564 0%, #2f3665 100%);
}

.tps {
  position: relative;
  // width: 90%;
  padding: 5%;
  color: #666;
  background: #efefef;
  line-height: 24px;
  font-size: 13px;
  text-align: left;
}

.tps h4 {
  font-size: 13px;
}
</style>
