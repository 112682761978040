<template>
  <div class="shareFriend">
    <header>
      <div class="g-header" style="background: rgb(14, 21, 48)">
        <div class="left" @click="changego">
          <van-icon name="arrow-left" color="#fff" size="20px" />
        </div>
        <div class="middle fs-18">{{ $t("我的团队") }}</div>
        <div class="right">
          <div class="bg-icon bg-icon-setting"></div>
        </div>
      </div>
      <div class="blockHeight"></div>
    </header>
    <div class="mescroll g-content">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="--END--"
          @load="onLoad"
        >
          <ul class="capital-list">
            <!-- 空数据的时候显示 -->
            <div class="mescroll-empty" v-if="list.length == []">
              <p class="empty-tip">{{ $t("wuTps") }}~</p>
              <p class="empty-btn" @click="BtnOk">{{ $t("jiazaicx") }}</p>
            </div>
            <!-- 有数据显示他 -->
            <template v-else>
              <div class="list-title fs-15">
                <span>ID</span>
                <span>{{ $t("类型") }}</span>
                <span>{{ $t("用户名") }}</span>
                <span>{{ $t("余额") }}</span>
              </div>
              <li
                class="capital-list-item fs-15"
                v-for="(item, index) in list"
                :key="index"
              >
                <span>{{ item.id }}</span>
                <span>{{ $t("yonghu") }}</span>
                <span>{{ item.username }}</span>
                <span style="color: red">{{ item.money }}</span>
              </li>
            </template>
          </ul>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>
<script>
import userApi from "@/api/user";
export default {
  name: "ShareFriend",
  components: {},
  data() {
    return {
      loading: false,
      finished: false,
      refreshing: false,
      list: [],
      page: 1,
      total: 0,
    };
  },
  methods: {
    onRefresh() {
      this.refreshing = false;
      this.finished = false;
      this.loading = true;
      this.BtnOk();
    },
    BtnOk() {
      this.$toast.loading({
        message: this.$t("jiazaizhong"),
        duration: 0,
        forbidClick: true,
      });
      this.getMyTeam();
      this.$toast.clear();
    },
    async getMyTeam() {
      let data = {
        page: this.page,
        pageSize: 20,
      };
      const res = await userApi.getMyTeam(data);
      if (res.code == 1) {
        this.list = res.data.data;
        this.total = res.data.total;
        this.loading = false;
        if (this.list.length == this.total) {
          this.finished = true;
        }
      }
      console.log(res, "res");
    },
    changego() {
      this.$router.go(-1);
    },
    onLoad() {
      console.log("触底了");
      if (this.loading) return;
      this.page++;
      this.loading = true;
      this.getMyTeam();
    },
  },
  computed: {
    userId() {
      console.log(this.$store.state.currentUserId);
      return this.$store.state.currentUserId;
    },
  },
  mounted() {
    this.onRefresh();
  },
};
</script>
<style lang="less" scoped>
.shareFriend {
  overflow: hidden;
}
.settingwarp {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.capital-list {
  padding: 0.2rem;
}
.mescroll {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  flex: 1;
}
.mescroll-empty {
  width: 100%;
  padding-top: 20px;
  text-align: center;
}
.empty-tip {
  margin-top: 6px;
  font-size: 14px;
  color: gray;
}
.empty-btn {
  max-width: 50%;
  margin: 20px auto;
  padding: 10px;
  border: 1px solid #65aadd;
  border-radius: 6px;
  background-color: white;
  color: #65aadd;
  background-color: #7d58c6 !important;
  border: 1px solid #3d5179 !important;
  color: #fff !important;
}
.capital-list-item {
  // background-color: #1a243f;
  margin-top: 0.4rem;
  border-radius: 3px;
  padding: 0.3rem 0;
  border-bottom: 1px solid #f2f2f2;
  display: flex;
  justify-content: space-between;
  span {
    flex: 1;
    text-align: center;
  }
}
.list-title {
  display: flex;
  justify-content: space-between;
  span {
    flex: 1;
    text-align: center;
  }
}
</style>
